import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CancellationComponent } from '../components/cancellation/cancellation/cancellation.component';
import { AmxCustomerInformationComponent } from '../components/carriers/amx/amx-customer-information/amx-customer-information.component';
import { AmxExcessLabelsComponent } from '../components/carriers/amx/amx-excess-labels/amx-excess-labels.component';
import { AmxFurtherDetailsComponent } from '../components/carriers/amx/amx-further-details/amx-further-details.component';
import { AmxParcelDetailsComponent } from '../components/carriers/amx/amx-parcel-details/amx-parcel-details.component';
import { AmxPaymentComponent } from '../components/carriers/amx/amx-payment/amx-payment.component';
import { AmxPreviewComponent } from '../components/carriers/amx/amx-preview/amx-preview.component';
import { AmxPurchasingCustomerDetailsComponent } from '../components/carriers/amx/amx-purchasing-customer-details/amx-purchasing-customer-details.component';
import { AmxReceiverDetailsComponent } from '../components/carriers/amx/amx-receiver-details/amx-receiver-details.component';
import { AmxRequiredSupportingInformationComponent } from '../components/carriers/amx/amx-required-supporting-information/amx-required-supporting-information.component';
import { AmxSenderDetailsComponent } from '../components/carriers/amx/amx-sender-details/amx-sender-details.component';
import { AmxTermsAndConditionsComponent } from '../components/carriers/amx/amx-terms-and-conditions/amx-terms-and-conditions.component';
import { AmxwelcomeComponent } from '../components/carriers/amx/amxwelcome/amxwelcome.component';
import { ApproveClaimComponent } from '../components/carriers/fmt/approve-claim/approve-claim.component';
import { BankDetailsComponent } from '../components/common/bank-details/bank-details.component';
import { CarrierSelectionComponent } from '../components/common/carrier-selection/carrier-selection.component';
import { ClaimInformationComponent } from '../components/common/claim-information/claim-information.component';
import { ConsignmentInformationComponent } from '../components/common/consignment-information/consignment-information.component';
import { ContactInformationComponent } from '../components/common/contact-information/contact-information.component';
import { FranchiseeDetailsComponent } from '../components/common/franchisee-details/franchisee-details.component';
import { PreviewComponent } from '../components/common/preview/preview.component';
import { ReceiverDetailsComponent } from '../components/common/receiver-details/receiver-details.component';
import { SenderDetailsComponent } from '../components/common/sender-details/sender-details.component';
import { SupportingDocumentsComponent } from '../components/common/supporting-documents/supporting-documents.component';
import { WelcomeComponent } from '../components/common/welcome/welcome.component';

@Injectable({
  providedIn: 'root'
})



export class ComponentRegistry {

  

  readonly registry: { [key: string]: any } = {
    'Welcome': WelcomeComponent,
    'Contact': ContactInformationComponent,
    'Consignment': ConsignmentInformationComponent,
    'Claim': ClaimInformationComponent,
    'Receiver': ReceiverDetailsComponent,
    'Sender': SenderDetailsComponent,
    'Document': SupportingDocumentsComponent,
    'Bank': BankDetailsComponent,
    'Franchisee': FranchiseeDetailsComponent,
    'Preview': PreviewComponent,
    'AMXWelcome': AmxwelcomeComponent,
    'AmxExcessLabel':AmxExcessLabelsComponent,
    'AmxPurchasingCustomer': AmxPurchasingCustomerDetailsComponent,
    'AmxCustomerInformation': AmxCustomerInformationComponent,
    'AmxParcelDetails': AmxParcelDetailsComponent,
    'AmxSenderDetails': AmxSenderDetailsComponent,
    'AmxReceiverDetails': AmxReceiverDetailsComponent,
    'AmxRequiredSupportingInformation': AmxRequiredSupportingInformationComponent,
    'AmxFurtherDetails': AmxFurtherDetailsComponent,
    'AmxPayment': AmxPaymentComponent,
    'AmxTermsAndConditions': AmxTermsAndConditionsComponent,
    'AmxPreview': AmxPreviewComponent,
    'Approve': ApproveClaimComponent,
    'Cancel': CancellationComponent,
    'CarrierSelection': CarrierSelectionComponent

  }


}
