import { Component, OnDestroy, OnInit } from '@angular/core';

import { ConfigurationModel } from '../../../models/ConfigurationModel';
import { Constant } from '../../../utilities/Constant';
import { CommonService } from '../../../services/common/common.service';
import { FormService } from '../../../services/common/form.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.css']
})
export class ContactInformationComponent implements OnInit, OnDestroy {
  carrierConfig!: ConfigurationModel;
  contactInformationForm = this.formService.contactInformationForm;
  claimantRoles = Constant.claimantRole;
  claimantPartyTypes = Constant.claimantTypes;
  showclaimantPartyType: boolean = false;
  showAccountNumber: boolean = true;
  displayNameAccNumber!: string | undefined;
  showMessage: boolean = false;
  carrierSpecificFieldMessage?: string;
  /* Subscription Variables */
  private claimantRoleSub!: Subscription;
  private claimantNameSub!: Subscription | undefined;
  private claimantContactNameSub!: Subscription | undefined;
  private claimantAccountNumberSub!: Subscription | undefined;


  get myForm() {
    return this.contactInformationForm?.controls;
  }
  get claimant() {
    return this.myForm?.claimant;
  }
  constructor(private commonService: CommonService, private formService: FormService) { }
  ngOnInit(): void {

    this.showclaimantPartyType = this.commonService.checkCustomFields('claimant.claimantPartyType');
    this.showAccountNumber = this.commonService.checkCustomFields('claimant.accountNumber', true);
    this.displayNameAccNumber = this.getDisplayNameCustomField('claimant.accountNumber', 'Account Number')

    this.claimantRoleSub = this.myForm.claimantRole.valueChanges.subscribe(() => {
      this.formService.updateSenderReceiver();
    });

    this.claimantNameSub = this.claimant.get('name')?.valueChanges?.subscribe(() => {
      this.formService.updateSenderReceiver();
    });

    this.claimantContactNameSub = this.claimant.get('claimantContact.name')?.valueChanges?.subscribe(() => {
      this.formService.updateSenderReceiver();
    });

    this.claimantAccountNumberSub = this.claimant.get('accountNumber')?.valueChanges?.subscribe(() => {
      this.formService.updateAccountNumber();
    });

    this.carrierSpecificFieldMessage = this.commonService.getCustomFieldCarrierSpecificMessage('claimant.accountNumber');
    if (this.carrierSpecificFieldMessage) {
      this.showMessage = true;
    }

  }
  /**
   * This is called by angular when the component is desctroied
   * Use this method to unsubscribe from all observerables with in the component.
   * By unsubscribing we make sure that there are no memory leaks in the future. */
  ngOnDestroy(): void {
    this.claimantRoleSub?.unsubscribe();
    this.claimantNameSub?.unsubscribe();
    this.claimantContactNameSub?.unsubscribe();
    this.claimantAccountNumberSub?.unsubscribe();
  }


  getDisplayNameCustomField(fieldName: string, defaultValue: string): string {
    return this.commonService.getCustomFieldDisplayName(fieldName,defaultValue);
  }


}
