import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ConfigurationModel } from '../../../models/ConfigurationModel';
import { ModalOptions } from '../../../models/ModalOptions';
import { SupportingDocumentType } from '../../../models/SupportingDocumentType';
import { CommonService } from '../../../services/common/common.service';
import { FormService } from '../../../services/common/form.service';
import { NavigationService } from '../../../services/common/navigation-service';
import { Constant } from '../../../utilities/Constant';
import { Utility } from '../../../utilities/Utility';
import { ModalComponent } from '../modal/modal.component';


@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit, OnDestroy {
  previewForm = this.formService.preview;
  dateFormat: string = Constant.dateFormat;
  dateOfDispatch: any;
  showInitialContactDetails: boolean = false;
  showDateOfReceipt: boolean = true;
  claimTypes: { id: number, name: string }[] = Constant.claimType;
  carrierPrefix!: string;
  carrierConfig: ConfigurationModel = this.commonService.getCarrierConfigObject();
  claimType = this.formService.consignmetInformationForm?.controls?.claimType?.value;
  carrierHasBankDetails: boolean = false;
  carrierHasFranchiceeDetails: boolean = false;
  showclaimantPartyType: boolean = false;
  isSalvageRequired: string = '';
  areGoodsRepairable: string = '';
  isClaimantInternational: string = '';
  showAccountNumber: boolean = true;
  displayNameAccNumber!: string | undefined;
  claimValueFieldDisplayName!: string;
  showReceiverPhoneNumber = false;
  showReceiverEmail = false;
  showIsInternationalClaiamnt = false;
  showinvoiceFile: boolean = true;
  carrierName!: string;
  consignmentNoteName!: string;
  dateOfReceiptName!: string;
  showPodFile: boolean = true;
  showImage4: boolean = true;
  terFileName!: string;
  terFileNameError!: string;
  imageFile1Name!: string;
  imageFile3Name!: string;
  showInternationalBankDetails!: boolean;

  // modal properties
 // @ViewChild('modal', { read: ModalComponent }) private modal!: ModalComponent;
  modalHeaderContent!: string;
  modalbodyContent!: string;
  showOkayButton!: boolean;
  showModalConfirmationButtons!: boolean;
  isHtmlContent: boolean = false;

  //Subscriptions
  claimTypeSub!: Subscription;
  internationalClaimantSub!: Subscription;
  isSalvageRequiredSub!: Subscription;
  areGoodsRepairableSub!: Subscription;
  attachmentSub!: Subscription;
  documentTypesSub!: Subscription;

  //Forms
  contactInformation = this.formService.contactInformationForm;
  consignmetInformation = this.formService.consignmetInformationForm;
  senderDetails = this.formService.senderDetailsForm;
  receiverDetails = this.formService.receiverDetailsForm;
  claimInformation = this.formService.claimInformationForm;
  supportingDocumentForm = this.formService.supportingDocumentForm;
  bankInformationDetail = this.formService.bankInformationDetailForm;
  franchiseeDetails = this.formService.franchiseeDetailsForm;

  // new document version
  documentTypes: SupportingDocumentType [] = []; 
  uploadedFiles = this.supportingDocument.attachments.value;

  //

  get contactForm() {
    return this.contactInformation?.controls;
  }
  get claimant() {
    return this.contactForm?.claimant;
  }
  get consignmentForm() {
    return this.consignmetInformation.controls;
  }
  get sender() {
    let _sender = this.senderDetails as UntypedFormGroup;
    return _sender.controls.sender;
  }
  get receiver() {
    let _receiver = this.receiverDetails as UntypedFormGroup;
    return _receiver.controls.receiver;
  }
  get claimInforForm() {
    return this.claimInformation.controls;
  }
  get supportingDocument() {
    return this.supportingDocumentForm.controls;
  }
  get bankInforForm() {
    return this.bankInformationDetail.controls;
  }
  get franchisee() {
    let _franchisee = this.franchiseeDetails as UntypedFormGroup
    return _franchisee.controls.franchisee;
  }
  get previewFormControl() {
    return this.previewForm.controls;
  }

  constructor(private formService: FormService, private commonService: CommonService,
    private modalService: NgbModal, private navigationService: NavigationService) {

  }

  ngOnInit(): void {
    this.formService.updateFormControlValidation();
    this.formService.contactInformationForm?.markAllAsTouched();
    this.formService.consignmetInformationForm?.markAllAsTouched();
    this.formService.senderDetailsForm?.markAllAsTouched();
    this.formService.receiverDetailsForm?.markAllAsTouched();
    this.formService.claimInformationForm?.markAllAsTouched();
    this.formService.supportingDocumentForm?.markAllAsTouched();
    this.formService.bankInformationDetailForm?.markAllAsTouched();
    this.formService.franchiseeDetailsForm?.markAllAsTouched();
    this.formService.preview?.markAllAsTouched();

    //this.carrierConfig = 
    this.carrierName = this.commonService.carrierName;
    //setting the initial contact details control
    this.showInitialContactDetails = this.commonService.checkCustomFields('initialContactDetails');
    this.showIsInternationalClaiamnt = this.commonService.checkCustomFields('isInternationalClaimant');
    this.showInternationalBankDetails = this.bankInforForm.isInternationalClaimant.value == 'true' && this.showIsInternationalClaiamnt ? true : false;
    this.showinvoiceFile = this.commonService.checkCustomFields('invoiceFile', true);
    this.showPodFile = this.commonService.checkCustomFields('podFile', true);
    this.showImage4 = this.commonService.checkCustomFields('imageFile4', true);
    //setting the customerType control
    this.showclaimantPartyType = this.commonService.checkCustomFields('claimant.claimantPartyType');
    //show hide bank details.
    this.carrierHasBankDetails = this.commonService.checkForCarrierSepecificComponent(Constant.customComponentNames.bank);
    //show hide franchisee details.
    this.carrierHasFranchiceeDetails = this.commonService.checkForCarrierSepecificComponent(Constant.customComponentNames.franchisee);

    //setting the date of receipt control
    let lostClaim = Constant.claimType.filter(c => c.name == 'Loss')[0];
    if (this.claimType?.id == lostClaim?.id) {
      this.showDateOfReceipt = false;
    } else {
      this.showDateOfReceipt = true;
    }
    // modal options
    //let options: ModalOptions = {
    //  size: 'xl',
    //  centered: true,
    //  isHtmlContent: true
    //}
    //this.formService.modalCallBackMethod = (header: string, body: string) => { return this.modal.modalOpen(header, body, options); };

    // set radio button options
    this.isSalvageRequired = this.formService.mapRadioButtonValues(this.consignmentForm.isSalvageRequired?.value);
    this.areGoodsRepairable = this.formService.mapRadioButtonValues(this.claimInforForm.isGoodsRepairable?.value);
    this.isClaimantInternational = this.formService.mapRadioButtonValues(this.bankInforForm.isInternationalClaimant?.value);

    this.showAccountNumber = this.commonService.checkCustomFields('claimant.accountNumber', true);
    this.displayNameAccNumber = this.getDisplayNameCustomField('claimant.accountNumber', 'Account Number')
    this.showReceiverEmail = this.commonService.checkCustomFields('receiver.email');
    this.showReceiverPhoneNumber = this.commonService.checkCustomFields('receiver.phone');
    let labelName = 'Value of Claim at Cost <em>(excluding GST)</em> ';
    this.claimValueFieldDisplayName = this.getDisplayNameCustomField('claimValue', labelName);
    this.claimValueFieldDisplayName = this.claimValueFieldDisplayName.concat(this.claimInforForm.claimValue?.hasError('required') ? ' <span *ngIf="myForm.claimValue?.errors?.required">*</span>' : '');

    this.consignmentNoteName = this.getDisplayNameCustomField('consignmentNoteNo', 'Consignment Number');
    this.dateOfReceiptName = this.getDisplayNameCustomField('dateOfReceipt', 'Date of Receipt')
    this.terFileName = this.getDisplayNameCustomField('terFile', 'Upload the Cost Price Invoice / <br/> Proof of Manufactured Cost');
    this.terFileNameError = `${this.terFileName} is required for claim submission`;
    this.imageFile1Name = this.getDisplayNameCustomField('imageFile1', 'Upload a photo');
    this.imageFile3Name = this.getDisplayNameCustomField('imageFile3', 'Upload an additional photo');

    this.documentTypes = this.formService.customizeDocumentTypes(this.carrierConfig, this.supportingDocumentForm);

    /*subscription - used when the reset button is clicked. */
    this.internationalClaimantSub = this.bankInforForm.isInternationalClaimant?.valueChanges?.subscribe(value => {
      this.isClaimantInternational = this.formService.mapRadioButtonValues(value);
      this.showInternationalBankDetails = value == 'true' ? true : false;
      /* before resetting the BSB number validation  check if the bank component is supported by the carrier*/
      if (value == 'false' && this.commonService.isCarrierSupportedComponent(Constant.customComponentNames.bank))
      {
        /*Sets the BSB Number validation only when it is not international*/
        let arrayOfValidators = this.formService.getALLBSBValidatorsToArray();
        this.bankInforForm.bsbNumber?.setValidators(arrayOfValidators);
      }
     
    });
    this.isSalvageRequiredSub = this.consignmentForm.isSalvageRequired?.valueChanges?.subscribe(value => {
      this.isSalvageRequired = this.formService.mapRadioButtonValues(value);
    });
    this.areGoodsRepairableSub = this.claimInforForm.isGoodsRepairable?.valueChanges?.subscribe(value => {
      this.areGoodsRepairable = this.formService.mapRadioButtonValues(value);
    });

    this.claimTypeSub = this.formService.consignmetInformationForm?.controls?.claimType?.valueChanges?.subscribe(value => {
      this.claimType = value;
    });

    this.attachmentSub = this.supportingDocument?.attachments?.valueChanges?.pipe().subscribe(value => {
      this.uploadedFiles = value;
    });

    this.documentTypesSub = this.supportingDocument?.documentTypes?.valueChanges?.pipe(distinctUntilChanged()).subscribe(value => {
      this.documentTypes = value;
    });
    
  }

  ngOnDestroy(): void {
    this.internationalClaimantSub?.unsubscribe();
    this.isSalvageRequiredSub?.unsubscribe();
    this.areGoodsRepairableSub?.unsubscribe();
    this.claimTypeSub?.unsubscribe();
    this.attachmentSub?.unsubscribe();
    this.documentTypesSub?.unsubscribe();
  }

  getDisplayNameCustomField(fieldName: string, defaultValue: string): string {
    return this.commonService.getCustomFieldDisplayName(fieldName, defaultValue);
  }

  getStringDateFromControl(control: AbstractControl): string {
    return Utility.convertDateToString(control);
  }

  editDetails(navigationPath: string) {
    this.navigationService.navigateToComponent(this.carrierConfig, undefined, navigationPath);
  }

}
