import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormRecord, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { RequiredStatus } from '../enums/RequiredStatus';
import { CustomValidationService } from '../services/common/custom-validation.service';
import { Constant } from './Constant';


@Injectable({
  providedIn: 'root'
})

export class FormRegistry {
  constructor(private formBuilder: UntypedFormBuilder, private customValidatorService: CustomValidationService,) { }

  readonly registry: { [key: string]: UntypedFormGroup  } = {
    'PreviousClaim': this.formBuilder.group({
      previousClaimNumber: ['', Validators.compose([this.customValidatorService.claimNumberPatternValidator()])],
      previousClaimLabelNumber: ['']
    }),

    'Welcome': this.formBuilder.group({
      carrier: [''],
      isPreviousClaimant: ['0'],
      previousClaimNumber: ['', Validators.compose([this.customValidatorService.claimNumberPatternValidator()])],
      previousClaimLabelNumber: [''],
      browser: [''],
      browserVersion: [''],
      ipAddress: [''],
      isDraftClaim: ['0'],
      draftClaimId: [''],
      alreadyChecked: [false]
    }),
    'Contact': this.formBuilder.group({
      claimant: this.formBuilder.group(
        {
          name: ['', [Validators.maxLength(100)]],
          claimantContact: this.formBuilder.group(
            {
              name: ['', [Validators.required, Validators.maxLength(35)]]
            }),
          accountNumber: ['',],
          email: ['', [Validators.required, this.customValidatorService.customEmailValidator()]],
          phone: ['', Validators.compose([this.customValidatorService.numericInputValidator(), Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
          claimantPartyType: ['',],
          address: ['',],
          addressLine1: ['',],
          city: ['',],
          postalCode: ['',],
          state: ['',],
          street: ['',],
          country: ['',],
          latitude: ['0',],
          longitude: ['0',],
        }),
      claimantRole: ['', [Validators.required]]
    }),
    'Consignment': this.formBuilder.group({
      consignmentNoteNo: ['', [Validators.required]],
      claimType: ['', [Validators.required]],
      goodsDispatchedDateTime: [null, Validators.compose([Validators.required, this.customValidatorService.datePatternValidator(), this.customValidatorService.dateValidator()])],
      goodsReceivedDateTime: [null, Validators.compose([Validators.required, this.customValidatorService.datePatternValidator(), this.customValidatorService.dateValidator()])], 
      isSalvageRequired: ['0'],
      salvageDetails: [''],
      initialContactDate: [null, Validators.compose([Validators.required, this.customValidatorService.datePatternValidator(), this.customValidatorService.dateValidator()])],
      initialContactDetails: ['',], //should be required if carrier has the field.
      isFreightInsureClaimRequired: ['false'],
      policyCarrierPrefix: [''],
      selectedFreightCarrier:null
    }),
    'Sender': this.formBuilder.group({
      sender: this.formBuilder.group(
        {
          name: ['', [Validators.required]],
          address: ['', [Validators.required]],
          addressLine1: [{ value: '', disabled: true }, [Validators.required]],
          city: [{ value: '', disabled: true }, [Validators.required]],
          state: [{ value: '', disabled: true }, [Validators.required]],
          postalCode: [{ value: '', disabled: true }, [Validators.required]],
          street: ['',],
          country: ['',],
          latitude: [0],
          longitude: [0],
          accountNumber: [''],
          claimantPartyType: ['']
        }, { validator: this.customValidatorService.customGroupValidator() })

    }),
    'Receiver': this.formBuilder.group({
      receiver: this.formBuilder.group({
        name: ['', [Validators.required]],
        address: ['', [Validators.required]],
        addressLine1: [{ value: '', disabled: true }, [Validators.required]],
        city: [{ value: '', disabled: true }, [Validators.required]],
        state: [{ value: '', disabled: true }, [Validators.required]],
        postalCode: [{ value: '', disabled: true }, [Validators.required]],
        street: ['',],
        country: ['',],
        latitude: [0],
        longitude: [0],
        email: [''],
        phone: [''],
        accountNumber: [''],
        claimantPartyType: ['']
      }, { validator: this.customValidatorService.customGroupValidator() }),
      //test: ['']
    }),
    'Claim': this.formBuilder.group({
      descriptionOfGoods: ['', [Validators.required]],
      descriptionOfLossOrDamage: ['', [Validators.required]],
      isGoodsRepairable: ['0'],
      damagedGoodsLocation: ['', [Validators.required]],
      claimValue: ['', [Validators.required, this.customValidatorService.numericInputwithdecimalNoZerosValidator()]]
    }),
    'Document': this.formBuilder.group({

      //podFile: ['', ],
      //cpiFile: ['', Validators.required],
      //terFile: ['', ],
      //consignmentFile: [''],
      //poDamageFiles: ['', ],
      //siFile: [''],
      //poPackageFiles: [''],
      //otherFiles: [''],
      attachments: [''],
      documentType: [''],
      documentTypes: [''],
      copyDocumentTypes: [''],
      uploadedTotalFileSize: [0]

    }),
    'Franchisee': this.formBuilder.group({
      franchisee: this.formBuilder.group({
        code: ['',],
        name: ['',],
        email: ['',],
        businessphone: ['',],
      })
    }),
    'Bank': this.formBuilder.group({

      isInternationalClaimant: ['false'],
      nameOfBank: [''], //[Validators.required]
      accountName: [''],//[Validators.required]
      accountNumber: [''], //[Validators.compose([this.customValidatorService.numericInputValidator(), Validators.required, Validators.maxLength(10), Validators.minLength(6)])], //
      bsbNumber: [''],//[Validators.required]
      bankAddress: [''],//[Validators.required]
      accountAddress: [''],//[Validators.required]
      bankABA: [''],//[Validators.required]
      bankSwift: [''],//[Validators.required]
      accountCurrency: [''],//[Validators.required]
      bsbInformation: ['']
    }),
    'Preview': this.formBuilder.group({
      isDeclarationChecked: ['', Validators.compose([Validators.required, this.customValidatorService.checkboxValueTrue()])]
    }),
    'Approve': this.formBuilder.group({
      consignmentNoteNo: ['', Validators.required],
      processingOption: ['',Validators.required],
      isWarrantyPaid: ['false'],
      warrantyRate: [''],
      claimantComments: [''],
      claimNumber: ['', Validators.required],
    }),

    'CarrierSelection': this.formBuilder.group({
      selectedCarrier: ['', Validators.required],
     
    }),

    'AmxPreviousClaim': this.formBuilder.group({
      previousClaimNumber: ['', Validators.compose([this.customValidatorService.claimNumberPatternValidator()])],
      previousClaimLabelNumber: ['']
    }),
    'AMXWelcome': this.formBuilder.group({
      isPreviousClaimant: ['0'],
      isDraftClaim: ['0'],
      previousClaimNumber: ['', Validators.compose([this.customValidatorService.claimNumberPatternValidator()])],
      previousClaimLabelNumber: [''],
      claimantRole: [''],
      claimNumber: [''],
      encodedClaimNumber: [''],
      claimId: [''],
      draftClaimLabelNumber: [''],
      draftClaimId: [''],
      carrier: [''],
      isFreightInsureClaimRequired: ['false'],
      policyCarrierPrefix: ['']

    }),
    'AmxPurchasingCustomer': this.formBuilder.group({
      purchasingCustomer: this.formBuilder.group(
        {
          name: [''],
          addressLine1: [''],
          street: [''],
          city: [''],
          state: [''],
          postalCode: [''],
          address: [''],
          contactName: [''],
          email: [''],
          officeNumber: [''],
          phone: [''],
          latitude: [0],
          longitude: [0]
        }),
      purchasingCustomerComment: ['']
    }),
    'AmxSenderDetails': this.formBuilder.group({
      sender: this.formBuilder.group(
        {
          name: ['', Validators.compose([this.customValidatorService.startWithCharactersOnly(), Validators.required])],
          addressLine1: ['', [Validators.required]],
          street: [''],
          city: ['', [Validators.required]],
          state: [''],
          postalCode: ['', [Validators.required]],
          country: [''],
          address: [{ value: '', disabled: true }],
          contactName: ['', [Validators.required]],
          email: ['', [Validators.required, this.customValidatorService.customEmailValidator()]],
          officeNumber: ['', Validators.compose([this.customValidatorService.numericInputValidator(), Validators.required, Validators.maxLength(10), Validators.minLength(10)])],
          phone: [''],
          latitude: [0],
          longitude: [0]
        }),
      senderComment: ['']
    }),
    'AmxReceiverDetails': this.formBuilder.group({
      receiver: this.formBuilder.group(
        {
          name: ['', Validators.compose([this.customValidatorService.startWithCharactersOnly(), Validators.required])],
          addressLine1: ['', [Validators.required]],
          street: [''],
          city: ['', [Validators.required]],
          state: [''],
          country: [''],
          postalCode: ['', [Validators.required]],
          address: [{ value: '', disabled: true }],
          contactName: ['', [Validators.required]],
          email: ['', [Validators.required, this.customValidatorService.customEmailValidator()]],
          officeNumber: ['', Validators.compose([this.customValidatorService.numericInputValidator(), Validators.required, Validators.maxLength(10), Validators.minLength(10)])],
          phone: [''],
          latitude: [0],
          longitude: [0]
        }),
      receiverComment: ['']
    }),
    'AmxCustomerInformation': this.formBuilder.group({

      isInternationalClaimant: ['false'],
      isInternationalClaimantInitially: ['false'],
      customerType: ['3'],
      claimantComment: [''],
      sender: this.formBuilder.group(
        {
          isRegisteredForGst: [''],
          abnNumber: [''],
          claimantPartyType: ['']
        }),
      receiver: this.formBuilder.group(
        {
          isRegisteredForGst: [''],
          abnNumber: [''],
          claimantPartyType: ['']
        }),
      purchasingCustomer: this.formBuilder.group(
        {
          isRegisteredForGst: [''],
          abnNumber: [''],
          claimantPartyType: ['']
        })
    }),
    'AmxRequiredSupportingInformation': this.formBuilder.group({
      //cpiFile: [''],
      //poDamageFiles: [''],
      //siFile: ['', Validators.required],
      //rqFile:[''],
      //poPackageFiles: [''],
      //otherFiles: [''],
      attachments: [''],
      documentType: [''],
      documentTypes: [''],
      copyDocumentTypes: [''],
      uploadedTotalFileSize: [0]
    }),
    'AmxParcelDetails': this.formBuilder.group({

      dispatchLocation: [''],
      originalTrackingReference: [''],
      labelNumber: [{ value: '', disabled: true }],
      aliasLabelNumber: [{ value: '', disabled: true }],
      packageType: [],
      dateTimeSent: [{ value: '', disabled: true }],
      isAdditionalLabelsDispatched: ['false']
    }),
    'AmxFurtherDetails': this.formBuilder.group({
      claimValue: ['', Validators.compose([Validators.required, this.customValidatorService.numericInputwithdecimalNoZerosValidator()])],
      claimCurrency: [''],
      isGoodsDamagedInTransitWine: ['0'],
      descriptionOfGoods: ['', [Validators.required]],
      descriptionOfLossOrDamage: ['', [Validators.required]],
      damagedGoodsLocation: [''],
      isGoodsRepairable: [''],
      claimType: ['', [Validators.required]],
      sender: this.formBuilder.group(
        {
          isClaimantWinery: ['0']
        }),
      receiver: this.formBuilder.group(
        {
          isClaimantWinery: ['0']
        }),
      purchasingCustomer: this.formBuilder.group(
        {
          isClaimantWinery: ['0']
        }),
      isSalvageRequired: ['false'],
      salvageDetails:['']
    }),
    'AmxPayment': this.formBuilder.group({
      accountName: ['', Validators.compose([Validators.required])],
      accountAddress: [''],
      nameOfBank: ['', [Validators.required]],
      bankAddress: [''],
      accountNumber: ['', Validators.compose([this.customValidatorService.numericInputValidator(), Validators.required, Validators.maxLength(10), Validators.minLength(6)])],
      bankABA: [''],
      bankSWIFT: [''],
      accountCurrency: [''],
      bsbNumber: [''],
      bsbInformation: ['']
    }),
    'AmxExcessLabel': this.formBuilder.group({
      excessLabels: ['',],
      excesslabelNumber: [''],
      excesslabelCost: [''],
      enableLabels: [0]

    }),
    'AmxTermsAndConditions': this.formBuilder.group({
      isAgreed: ['', Validators.compose([Validators.required, this.customValidatorService.checkboxValueTrue()])],
      date: [{ value: '', disabled: true }],
      ipAddress: [{ value: '', disabled: true }],
      browser: [''],
      browserVersion: ['']
    }),
    'AmxPreview': this.formBuilder.group({}),
    'Cancel': this.formBuilder.group({
      consignmentNoteNo: [''],
      claimNumber: [''],
      cancelReason: [''],
      description: ['', Validators.compose([this.customValidatorService.customCancellationReasonValidator()])]
    })
  }

 
}

