<div>
  <fieldset>
    <h3 class="multisteps-form__title">Contact Information</h3>
    <div class="multisteps-form__content">
      <form [formGroup]="contactInformationForm">
        <div formGroupName="claimant">
          <div class="form-row mt-4">
            <div class="form-group row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label" for="inputClaimant">Company Name</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" formControlName="name" id="inputClaimant" name="name" placeholder="Company Name" maxlength="100">
              </div>
              <div class="col-md-4 col-sm-12"></div>
            </div>
          </div>
          <div class="form-row mt-4" formGroupName="claimantContact">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label" for="inputClaimantContact">Contact Name <span *ngIf="claimant.get('claimantContact.name')?.errors?.required">*</span> </label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" formControlName="name" id="inputClaimantContact" name="name" placeholder="Contact Name" maxlength="35">
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="claimant.get('claimantContact.name')?.touched && claimant.get('claimantContact.name')?.errors?.required " class="help-block error">Required</span>
              </div>
            </div>
          </div>
          <div class="form-row mt-4" *ngIf="showAccountNumber">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label" for="inputCustomerNumber">{{displayNameAccNumber}} <span *ngIf="claimant.get('accountNumber')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" formControlName="accountNumber" id="inputCustomerNumber" name="inputCustomerNumber" [placeholder]="displayNameAccNumber">
                <label *ngIf="showMessage && claimant.get('accountNumber')?.errors?.required" class="notice mb-0">{{carrierSpecificFieldMessage}}</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <div [ngClass]="{'help-block error' : (claimant.get('accountNumber')?.errors?.required && claimant.get('accountNumber')?.touched)
                   ||(claimant.get('accountNumber')?.errors && claimant.get('accountNumber')?.value)}">
                  <span *ngIf="claimant.get('accountNumber')?.touched && claimant.get('accountNumber')?.errors?.required ">Required<br /></span>
                  <span *ngIf="claimant.get('accountNumber')?.errors?.maxlength">The {{displayNameAccNumber}} is invalid<br /></span>
                  <span *ngIf="claimant.get('accountNumber')?.errors?.invalidNumber ">The {{displayNameAccNumber}} should be numeric<br /></span>
                  <span *ngIf="claimant.get('accountNumber')?.errors?.invalidCharacter ">The {{displayNameAccNumber}} should be character<br /></span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label" for="inputClaimantEmail">Email Address <span *ngIf="claimant.get('email')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="email" class="form-control" formControlName="email" id="inputClaimantEmail" name="inputClaimantEmail" placeholder="Email Address" maxlength="254">
              </div>
              <div class="col-md-4 col-sm-12">
                <div [ngClass]="{'help-block error' : (claimant.get('email')?.errors?.required && claimant.get('email')?.touched)
                   ||(claimant.get('email')?.errors && claimant.get('email')?.value)}">
                  <span *ngIf="claimant.get('email')?.touched && claimant.get('email')?.errors?.required">Required<br /></span>
                  <span *ngIf="claimant.get('email')?.errors?.invalidEmailFormat">Email format Invalid. <br /> Ex:- "name@domain.com.au"</span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label" for="inputClaimantPhone">Phone Number <span *ngIf="claimant.get('phone')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" formControlName="phone" id="inputClaimantPhone" name="inputClaimantPhone" placeholder="Phone Number" numbers-only ng-maxlength="10" ng-minlength="10">
              </div>
              <div class="col-md-4 col-sm-12">
                <div [ngClass]="{'help-block error' :(claimant.get('phone')?.errors?.required && claimant.get('phone')?.touched)
                   ||(claimant.get('phone')?.errors && claimant.get('phone')?.value)}">
                  <span *ngIf="claimant.get('phone')?.touched && claimant.get('phone')?.errors?.required">Required<br /></span>
                  <span *ngIf="claimant.get('phone')?.errors?.maxlength">Phone number must be 10 digits<br /></span>
                  <span *ngIf="claimant.get('phone')?.errors?.minlength">Phone number must be 10 digits<br /></span>
                  <span *ngIf="claimant.get('phone')?.errors?.invalidNumber">The phone number should be numeric<br /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Claimant Role <span *ngIf="myForm.claimantRole?.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <select class="form-control" formControlName="claimantRole" id="dropClaimantRole" name="dropClaimantRole">
                <!--(change)="claimantRoleChange($event)"-->
                <option selected disabled value="">Please Choose</option>
                <option *ngFor="let role of claimantRoles" [ngValue]="role">{{role.value}}</option>
              </select>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="myForm.claimantRole?.touched && myForm.claimantRole?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>
        <div formGroupName="claimant">
          <div class="form-row mt-4" *ngIf="showclaimantPartyType">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right" ng-class="{'has-error' : formClaimForm.dropClaimantType.$dirty && formClaimForm.dropClaimantType.$invalid}">
                <label class="control-label" for="inputClaimantRole">Customer Type <span *ngIf="claimant.get('claimantPartyType')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <select class="form-control" formControlName="claimantPartyType" id="dropClaimantType" name="claimantPartyType">
                  <option selected disabled value="">Please Choose</option>
                  <option *ngFor="let type of claimantPartyTypes" [ngValue]="type">{{type.value}}</option>
                </select>
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="claimant.get('claimantPartyType')?.touched && claimant.get('claimantPartyType')?.errors" class="help-block error">Required</span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>   <!-- multisteps-form__content-->
  </fieldset>
</div>
