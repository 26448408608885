import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ConfigurationModel } from '../../models/ConfigurationModel';
import { Constant } from '../../utilities/Constant';
import { FormRegistry } from '../../utilities/FormRegistry';
import { AppService } from './app.service';
import { CustomValidationService } from './custom-validation.service';
import { NavigationService } from './navigation-service';
//import { MapsAPILoader } from '@agm/core';
import { Utility } from '../../utilities/Utility';
import { CommonService } from './common.service';
import { DocumentTypeUploads, DraftFile, SupportingDocumentType } from '../../models/SupportingDocumentType';
import { RequiredStatus } from '../../enums/RequiredStatus';
import { Device } from '../../enums/Device';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Config } from 'protractor';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';




@Injectable({
  providedIn: 'root'
})
export class FormService {

  private _claimFormSessionKey: string = 'claimFormArray';
 private apiLoaded!: Observable<boolean>
  constructor(private formBuilder: UntypedFormBuilder, private customValidatorService: CustomValidationService,
    private appService: AppService, private formRegistry: FormRegistry,
    private navigationService: NavigationService,
    private commonService: CommonService, httpClient: HttpClient) {
    this.apiLoaded = httpClient
      .jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}&libraries=places`, 'callback')
      .pipe(map(() => true),
        catchError(() => of(false)));
  }

  private _previousClaimForm = this.formRegistry.registry['PreviousClaim'];
  private _welcomeForm = this.formRegistry.registry[Constant.claimFormComponentNames.Welcome];

  private _contactInformationForm = this.formRegistry.registry[Constant.claimFormComponentNames.Contact];

  private _consignmetInformationForm =  this.formRegistry.registry[Constant.claimFormComponentNames.Consignment];

  private _senderDetailsForm = this.formRegistry.registry[Constant.claimFormComponentNames.Sender];

  private _receiverDetailsForm = this.formRegistry.registry[Constant.claimFormComponentNames.Receiver];

  private _claimInformationForm = this.formRegistry.registry[Constant.claimFormComponentNames.Claim];

  private _supportingDocumentForm = this.formRegistry.registry[Constant.claimFormComponentNames.Document];

  private _franchiseeDetailsForm = this.formRegistry.registry[Constant.claimFormComponentNames.Franchisee];

  private _bankInformationDetailForm = this.formRegistry.registry[Constant.claimFormComponentNames.Bank];

  private _previewForm = this.formRegistry.registry[Constant.claimFormComponentNames.Preview];

  private _approveForm = this.formRegistry.registry[Constant.claimFormComponentNames.Approve];

  private _carrierSelectionForm = this.formRegistry.registry[Constant.claimFormComponentNames.CarrierSelection]

  private _claimForm = this.formBuilder.array(
    [this.welcomeForm,
    this.contactInformationForm,
    this.consignmetInformationForm,
    this.receiverDetailsForm,
    this.senderDetailsForm,
    this.claimInformationForm,
    this.bankInformationDetailForm,
    this.franchiseeDetailsForm,
    this.supportingDocumentForm,
    this.preview
    ])
  /* Common to AMX and Warranty */
  private _supportingDocumentTypes: SupportingDocumentType[] = [];

  public get previousClaimForm() {
    return this._previousClaimForm;
  }
  public get welcomeForm() {
    return this._welcomeForm;
  }
  public get contactInformationForm() {
    return this._contactInformationForm;
  }
  public get consignmetInformationForm() {
    return this._consignmetInformationForm;
  }
  public get senderDetailsForm() {
    return this._senderDetailsForm;
  }
  public get receiverDetailsForm() {
    return this._receiverDetailsForm;
  }
  public get claimInformationForm() {
    return this._claimInformationForm;
  }
  public get supportingDocumentForm() {
    return this._supportingDocumentForm;
  }
  public get franchiseeDetailsForm() {
    return this._franchiseeDetailsForm;
  }
  public get bankInformationDetailForm() {
    return this._bankInformationDetailForm;
  }
  public get preview() {
    return this._previewForm;
  }
  public get approve() {
    return this._approveForm;
  }

  public get carrierSelectionForm() {
    return this._carrierSelectionForm
  }

  public get claimForm() {
    return this._claimForm;
  }

  public get supportingDocumentTypes() {
    if (this._supportingDocumentTypes.length > 0) {
      return this._supportingDocumentTypes;
    } else {
      return this.commonService.getLocalStorageObject(Constant.suportingDocumentTypesKey);
    }
  }
  public set supportingDocumentTypes(value) {
    if (value) {
      this.commonService.addKeyToSessionStorage(Constant.suportingDocumentTypesKey, JSON.stringify(value));
    }
  }

  private get claimant() {
    return this.contactInformationForm?.controls?.claimant;
  }
  private get sender() {
    return this.senderDetailsForm?.controls?.sender;
  }
  private get receiver() {
    return this.receiverDetailsForm?.controls?.receiver;
  }
  private get claimantRole() {
    return this.contactInformationForm?.controls?.claimantRole;
  }

  public get isFreightInsureClaimRequired() {
   return this.consignmetInformationForm.controls.isFreightInsureClaimRequired.value;
  }
  public get policyCarrierPrefix() {
    return this.consignmetInformationForm.controls.policyCarrierPrefix.value;
  }
  
  private _modalCallBackMethod!: (header: string, body: string) => NgbModalRef
  /**
   * Method used to call the modal from the service.
   */
  public set modalCallBackMethod(value: (header: string, body: string) => NgbModalRef) {
    this._modalCallBackMethod = value;
  }
  /**
   * Updates the sender and Receiver details.
   * Updated Fields : Name,Email,Phone,AccountNumber,ClaimantPartyType*/
  public updateSenderReceiver() {
    let claimantName = this.claimant?.get('name')?.value;
    let claimantContactName = this.claimant?.get('claimantContact.name')?.value;

    this.updateAccountNumber();

    if (this.claimantRole && this.claimantRole.value.id > -1) {
      switch (this.claimantRole.value.id) {
        case Constant.claimantRole[0].id:
          {
            if (this.sender?.get('address')?.value) {
              this.sender.enable({ onlySelf: true });
              this.receiver.disable({ onlySelf: true });
              this.receiver?.get('email')?.enable({ onlySelf: true });
              this.receiver?.get('phone')?.enable({ onlySelf: true });
              this.receiver?.get('address')?.enable({ onlySelf: true });
            }
            this.sender?.get('name')?.setValue(claimantName ? claimantName : claimantContactName);
            if (this.sender?.get('name')?.value) {
              this.sender?.get('name')?.disable({ onlySelf: true });
              this.receiver?.get('name')?.enable({ onlySelf: true });
            }

            break;
          }
        case Constant.claimantRole[1].id: {

          if (this.receiver?.get('address')?.value) {
            this.receiver.enable({ onlySelf: true });
            this.sender.disable({ onlySelf: true });
            this.sender?.get('email')?.enable({ onlySelf: true });
            this.sender?.get('phone')?.enable({ onlySelf: true });
            this.sender?.get('address')?.enable({ onlySelf: true });
          }
          this.receiver?.get('name')?.setValue(claimantName ? claimantName : claimantContactName);
          if (this.claimant && this.claimant.value && this.claimant.value.email) {
            this.receiver?.get('email')?.setValue(this.claimant.value.email);
          }
          if (this.claimant && this.claimant.value && this.claimant.value.phone) {
            this.receiver?.get('phone')?.setValue(this.claimant.value.phone);
          }

          if (this.receiver?.get('name')?.value) {
            this.receiver?.get('name')?.disable({ onlySelf: true });
            this.sender?.get('name')?.enable({ onlySelf: true });
          }
          break;
        }
        case Constant.claimantRole[2].id:
          {
            this.sender?.get('name')?.enable({ onlySelf: true });
            this.receiver?.get('name')?.enable({ onlySelf: true });

          }
      }
    }
  }
  /**
   * Sets the account number of the sender or receiver.
   * Based on the claimant role.*/
  public updateAccountNumber() {
    if (this.claimantRole && this.claimantRole.value?.id > -1) {
      switch (this.claimantRole.value.id) {
        case Constant.claimantRole[0].id:
          this.sender?.get('accountNumber')?.setValue(this.claimant.get('accountNumber')?.value);
          break;
        case Constant.claimantRole[1].id:
          this.receiver?.get('accountNumber')?.setValue(this.claimant.get('accountNumber')?.value);
          break;
      }
    }
  }
  /**
   * Sets the claimant Party type field.
   * Based on the claimant role.*/
  public updateClaimantType() {
    if (this.claimantRole && this.claimantRole.value?.id > -1) {
      switch (this.claimantRole.value.id) {
        case Constant.claimantRole[0].id:/* Sender */
          if (this.claimant.get('claimantPartyType')?.value) {
            this.sender?.get('claimantPartyType')?.setValue(this.claimant.get('claimantPartyType')?.value);
          } else {
            this.sender?.get('claimantPartyType')?.setValue(0);
            this.claimant.get('claimantPartyType')?.setValue(0);
          }
          this.receiver?.get('claimantPartyType')?.setValue(0);

          break;
        case Constant.claimantRole[1].id: /* Receiver */
          if (this.claimant.get('claimantPartyType')?.value) {
            this.receiver?.get('claimantPartyType')?.setValue(this.claimant.get('claimantPartyType')?.value);
          } else {
            this.receiver?.get('claimantPartyType')?.setValue(0);
            this.claimant.get('claimantPartyType')?.setValue(0);
          }
          this.sender?.get('claimantPartyType')?.setValue(0);
          break;

      }
    }
  }

  /**
   * Removes validations from a component form and updated validity status.
   * @param componentName The compoment that validation is to be removed.
   */
  public removeValidationByComponentName(componentName: string) {
    let form = this.formRegistry.registry[componentName];
    let keys = Object.keys(form.controls);
    for (let j = 0; j < keys.length; j++) {
      form.get(keys[j])?.clearValidators();
      form.get(keys[j])?.updateValueAndValidity();
    }
  }

  /**
   * Adds custom validators based on the carrier config json.
   * Use this method to specify a component and add validation to the form.
   * @param carrierConfig configuration JSON to check for carrier specify validation.
   * @param componentName specify the compnent name.
   */
  public addCarrierSpecificValidationByComponent(carrierConfig: ConfigurationModel, componentName: string) {
    let form = this.formRegistry.registry[componentName];
    let keys = Object.keys(form.controls);
    let customValidationFields = carrierConfig?.customFields?.filter(config => config.validators != undefined);
    if (customValidationFields && customValidationFields.length > 0) {
      keys.forEach(key => {
        let field = customValidationFields?.filter(customField => customField.fieldName == key)[0];
        if (field) {
          let validatorArray: (ValidatorFn | null | undefined)[] = []
          field.validators?.forEach(validtor => {
            let fieldValidator = this.mapValidatorByName(validtor);
            if (fieldValidator) {
              validatorArray.push(this.customValidatorService.customRequiredValidator(fieldValidator, validtor, //fieldValidator
                validtor.requiredStatus, null, this.claimForm));
            }
          });
          for (let i = 0; i < this.claimForm.controls.length; i++) {
            let form = this.claimForm.get([i]) as UntypedFormGroup
            let control = form.get(field.fieldName)
            if (control) {
              control.setValidators(Validators.compose(validatorArray));
              control.updateValueAndValidity();
            }
          }
        }
      });

    }
  }

  /**
   * Adds custom validation to the warranty claim forms fields based on the carrier config object. 
   * @param carrierConfig carrier configuration object.
   */
  public addCarrierSpecificValidation(carrierConfig: ConfigurationModel) {
    let customValidationFields = carrierConfig?.customFields?.filter(config => config.validators != undefined);
    if (customValidationFields && customValidationFields.length > 0) {
      customValidationFields.forEach(field => {
        let validatorArray: (ValidatorFn | null | undefined)[] = []
        field.validators?.forEach(validtor => {
          let fieldValidator = this.mapValidatorByName(validtor);
          //let defaultValidator = this.mapValidatorByName(field.defaultValidator);
          if (fieldValidator) {
            validatorArray.push(this.customValidatorService.customRequiredValidator(fieldValidator, validtor, //fieldValidator
              validtor.requiredStatus, null, this.claimForm));
            // this.claimForm.updateValueAndValidity();
          }
        });
        for (let i = 0; i < this.claimForm.controls.length; i++) {
          let form = this.claimForm.get([i]) as UntypedFormGroup
          let control = form.get(field.fieldName)
          if (control) {
            control.setValidators(Validators.compose(validatorArray));
            control.updateValueAndValidity();
          }
        }
      });
    }


  }

  /**
   * maps the actual validator based on the string name provided.
   * @param validator
   */
  private mapValidatorByName(validator: { name: string, maxlimit?: number, minlimit?: number }): ValidatorFn | null {

    if (validator.name.toLocaleLowerCase() == Constant.validators.maxLengthValidator.toLocaleLowerCase()) {
      return Validators.maxLength(validator.maxlimit ? validator.maxlimit : 10);
    } else if (validator.name.toLocaleLowerCase() == Constant.validators.minLengthValidator.toLocaleLowerCase()) {
      return Validators.minLength(validator.minlimit ? validator.minlimit : 10);
    } else if (validator.name.toLocaleLowerCase() == Constant.validators.numericValidator.toLocaleLowerCase()) {
      return this.customValidatorService.numericInputValidator();
    } else if (validator.name.toLocaleLowerCase() == Constant.validators.characterValidator.toLocaleLowerCase()) {
      return this.customValidatorService.charactersOnlyValidator();
    } else if (validator.name.toLocaleLowerCase() == Constant.validators.characterNumberValidator.toLocaleLowerCase()) {
      return this.customValidatorService.charactersAndNumbersOnlyValidator();
    } else if (validator.name.toLocaleLowerCase() == Constant.validators.startsWithChar.toLocaleLowerCase()) {
      return this.customValidatorService.startWithCharactersOnly();
    } else if (validator.name.toLocaleLowerCase() == Constant.validators.emailValidator.toLocaleLowerCase()) {
      return Validators.email;
    } else if (validator.name.toLocaleLowerCase() == Constant.validators.customEmailValidator.toLocaleLowerCase()) {
      return this.customValidatorService.customEmailValidator(); //customemail
    } else if (validator.name.toLocaleLowerCase() == Constant.validators.requiredValidator.toLocaleLowerCase()) {
      return Validators.required;
    }
    return null;
  }
  /**
   * Sets the retrieved previous claim data to the new claim form.
   * @param claim - retrieved previous claim.
   */
  public setPreviouseClaimData(claim: any) {

    /* Reset contact information, sender information, receiver information */
    this.sender.reset();
    this.sender.get('state')?.setValue('', { onlyself: true });
    this.receiver.reset();
    this.receiver.get('state')?.setValue('', { onlyself: true });
    this.contactInformationForm.reset();

    /* maps th claimant object */
    let claimantObject = this.mapClaimFormData(claim, 'claimant');
    if (claimantObject) {

      this.contactInformationForm.get('claimant')?.patchValue(claimantObject, { onlyself: true });

      /*map claimant mobile number with the phone field, From mapped claimant object */
      this.contactInformationForm.get('claimant.phone')?.setValue(claimantObject['mobileNumber'], { onlyself: true });

      /* map the claimantContact if object is null, From mapped claimant object */
      let claimantContact = !claimantObject['claimantContact'] ? { name: claimantObject['contactName'] } : claimantObject['claimantContact'];
      this.contactInformationForm.get('claimant.claimantContact')?.setValue(claimantContact, { onlyself: true });

      /* map the customerType, from the claimant object. */
      let customerType = Constant.claimantTypes.filter(ct => {
        if (claimantObject) {
          return ct.id == parseInt(claimantObject['claimantPartyType'])
        }
        return;
      })[0]
      this.contactInformationForm.get('claimant.claimantPartyType')?.setValue(customerType, { onlyself: true });

      /* map rest of the claim form fields */
      let mappedObject = this.mapClaimFormData(claim);

      if (mappedObject) {
        /* Setting the claimant role dropdownlist */
        let claimantRole = Constant.claimantRole.filter(cr => {
          if (mappedObject) {
            return cr.id == parseInt(mappedObject['claimantRole'])
          }
          return;
        }
        )[0]
        this.contactInformationForm.get('claimantRole')?.setValue(claimantRole, { onlyself: true });

        /*Map claimant Address to sender or receiver based on role*/
        switch (claimantRole.id) {
          case 0: {
            this.sender.patchValue(claimantObject, { onlyself: true });
            break
          };
          case 1: {
            this.receiver.patchValue(claimantObject, { onlyself: true });
            break
          };
          default: {
            //this.sender.patchValue(claimantObject, { onlyself: true });
            break
          }
        }

        /*Setting the Bank details if they are available.*/
        this.bankInformationDetailForm.patchValue(mappedObject);

        /* map the international Claimant, from the form object. */
        let internationalClaimant = mappedObject['isInternationalClaimant'] ? 'true' : 'false';
        this.bankInformationDetailForm.get('isInternationalClaimant')?.setValue(internationalClaimant, { onlyself: true });
        let carrierSupportsInternationalClaimants = this.commonService.checkCustomFields('isInternationalClaimant');
        /* map the currancy, from the form object. */
        if (internationalClaimant == 'true' && carrierSupportsInternationalClaimants) {
          let accountCurrancy = mappedObject['accountCurrency'];
          this.bankInformationDetailForm.get('accountCurrency')?.setValue(accountCurrancy, { onlyself: true });
          /* map the bankSwift, from the form object. */
          let bankSWIFT = mappedObject['bankSWIFT'];
          this.bankInformationDetailForm.get('bankSwift')?.setValue(bankSWIFT, { onlyself: true });

          this.bankInformationDetailForm.get('bsbNumber')?.setValue('', { onlyself: true });

        } else {
          this.bankInformationDetailForm.get('isInternationalClaimant')?.setValue('false', { onlyself: true });
          this.bankInformationDetailForm.get('bankSwift')?.setValue('', { onlyself: true });
          this.bankInformationDetailForm.get('bankAddress')?.setValue('', { onlyself: true });
          this.bankInformationDetailForm.get('accountAddress')?.setValue('', { onlyself: true });
          this.bankInformationDetailForm.get('bankABA')?.setValue('', { onlyself: true });
          this.bankInformationDetailForm.get('accountCurrency')?.setValue('', { onlyself: true });


        }

      }
    }
  }

  private mapClaimFormData(objectToMap: any, objectKey: string = '') {
    let obj = objectKey ? objectToMap[objectKey] : objectToMap;
    if (obj) {
      let key, newKey, keys = Object.keys(obj);
      let firstLetter: string;
      let n = keys.length;
      let newObject: { [key: string]: string } = {}
      while (n--) {
        key = keys[n];
        firstLetter = key.substr(0, 1).toLowerCase();
        newKey = key.replace(key.substr(0, 1), firstLetter)

        // Map an empty string if the object is null
        newObject[newKey] = obj[key] == null ? "" : (typeof obj[key] === "string") ? obj[key].trim() : obj[key];
      }
      return newObject;
    }
    return;
  }
  /** Maps the senders or receivers data to the claimant. */
  private mapClaimantInforBasedOnRole() {
    switch (this.claimantRole.value.id) {
      case 0: // sender
        this.claimant.get('address')?.setValue(this.sender.get('address')?.value);
        this.claimant.get('addressLine1')?.setValue(this.sender.get('addressLine1')?.value);
        this.claimant.get('city')?.setValue(this.sender.get('city')?.value);
        this.claimant.get('postalCode')?.setValue(this.sender.get('postalCode')?.value);
        this.claimant.get('state')?.setValue(this.sender.get('state')?.value);
        this.claimant.get('street')?.setValue(this.sender.get('street')?.value);
        this.claimant.get('country')?.setValue(this.sender.get('country')?.value);
        this.claimant.get('latitude')?.setValue(this.sender.get('latitude')?.value);
        this.claimant.get('longitude')?.setValue(this.sender.get('longitude')?.value);
        break;
      case 1: // receiver

        this.claimant.get('address')?.setValue(this.receiver.get('address')?.value);
        this.claimant.get('addressLine1')?.setValue(this.receiver.get('addressLine1')?.value);
        this.claimant.get('city')?.setValue(this.receiver.get('city')?.value);
        this.claimant.get('postalCode')?.setValue(this.receiver.get('postalCode')?.value);
        this.claimant.get('state')?.setValue(this.receiver.get('state')?.value);
        this.claimant.get('street')?.setValue(this.receiver.get('street')?.value);
        this.claimant.get('country')?.setValue(this.receiver.get('country')?.value);
        this.claimant.get('latitude')?.setValue(this.receiver.get('latitude')?.value);
        this.claimant.get('longitude')?.setValue(this.receiver.get('longitude')?.value);
        break;
    }
  }

  public setGoodsReceivedDate(value: any, carrierConfigObject: ConfigurationModel) {
    let showDateOfReceipt: boolean;
    let isRequiredFromConfig: boolean = true;
    let lostClaim = Constant.claimType.filter(c => c.id == 1)[0];
    let requiredValidator;
    // check the config validation value before setting.
    let goodsReceivedDate = carrierConfigObject?.customFields?.filter(customfield => customfield.fieldName == 'goodsReceivedDateTime')[0];
    if (goodsReceivedDate && goodsReceivedDate.validators) {
      requiredValidator = goodsReceivedDate.validators.filter(val => val.name.toLowerCase() === Constant.validators.requiredValidator.toLowerCase())[0];

    }
    if (value?.id == lostClaim?.id) {
      showDateOfReceipt = false;
      this.consignmetInformationForm.get('goodsReceivedDateTime')?.reset();
      this.consignmetInformationForm.get('goodsReceivedDateTime')?.clearValidators();

    } else {
      showDateOfReceipt = true;
      if (requiredValidator) {
        if (requiredValidator.requiredStatus == RequiredStatus.Required) {
          this.consignmetInformationForm.get('goodsReceivedDateTime')?.setValidators(Validators.compose([Validators.required, this.customValidatorService.datePatternValidator(),
          this.customValidatorService.dateValidator()]));
        }
      } else {
        this.consignmetInformationForm.get('goodsReceivedDateTime')?.setValidators(Validators.compose([Validators.required, this.customValidatorService.datePatternValidator(),
        this.customValidatorService.dateValidator()]));

      }
    }
    this.consignmetInformationForm.get('goodsReceivedDateTime')?.updateValueAndValidity();
    return showDateOfReceipt;
  }

  public getALLBSBValidatorsToArray() {
    // used any here because there are two types that need to be assigned to this array [ValidationErrors and ValidationFn]
    let validationArray: any[] = [Validators.required, Validators.maxLength(6), Validators.minLength(6), this.customValidatorService.numericInputValidator()];
    return validationArray;

  }

  private mapsubObjects(mapToObject: { [key: string]: any } = {}, subForm: UntypedFormGroup) {
    let subkeys = Object.keys(subForm.controls);
    for (let p = 0; p < subkeys.length; p++) {
      if (subForm.get(subkeys[p])?.value != null && subForm.get(subkeys[p])?.value != undefined &&
        subForm.get(subkeys[p])?.value !== '') {
        mapToObject[subkeys[p]] = subForm.get(subkeys[p])?.value;
        if ((subkeys[p] == 'isRegisteredForGst' || subkeys[p] == 'isClaimantWinery')
          && subForm.get(subkeys[p])?.value != null) {
          let value = subForm.get(subkeys[p])?.value;
          mapToObject[subkeys[p]] = value == '1' ? true : false
        }
      }
    }
  }

  /**
   updates the damange location field and re-initalises the field value. */
  public updateDamageLocationBasedOnClaimType(carrierConfigObject: ConfigurationModel) {

    let result = this.checkForCustomFiledValidators(carrierConfigObject, 'damagedGoodsLocation');
    if (result == false) {
      return;
    }

    if (this.consignmetInformationForm.get('claimType')?.value?.id == 1) {
      this.claimInformationForm?.get('damagedGoodsLocation')?.clearValidators();

    } else {
      this.claimInformationForm?.get('damagedGoodsLocation')?.setValidators(Validators.required);
    }
    this.claimInformationForm?.get('damagedGoodsLocation')?.updateValueAndValidity();
  }

  public checkForCustomFiledValidators(carrierConfigObject: ConfigurationModel, controlName: string) {

    // check the config validation value before setting.
    let field = carrierConfigObject?.customFields?.filter(customfield => customfield.fieldName == controlName)[0];
    if (field && field.validators) {
      let requiredValidator = field.validators.filter(val => val.name === Constant.validators.requiredValidator)[0];
      if (requiredValidator) {
        if (requiredValidator.requiredStatus === RequiredStatus.Required) {
          return true;
        } else if (requiredValidator.requiredStatus === RequiredStatus.NotRequired) {
          return false;
        }
      }
    }
    return null;
  }




  /** Sets the sender of receiver street address if null */
  public updateStreetAddress() {
    // Update street address if empty.
    if (!this.sender.get('street')?.value) {
      this.sender.get('street')?.setValue(this.sender.get('addressLine1')?.value)
    }
    if (!this.receiver.get('street')?.value) {
      this.receiver.get('street')?.setValue(this.receiver.get('addressLine1')?.value)
    }
  }

  /**
   * reset the claimform and re-initialize the data.
   * @param carrierConfig
   */
  public resetClaimForm(carrierConfig: ConfigurationModel | undefined = undefined) {
    if (carrierConfig) {
      this.resetClaimFormFieldsAndStoreSession(carrierConfig);
      let step = carrierConfig?.navigation?.filter(n => n.componentName == Constant.claimFormComponentNames.Preview)[0]
      if (step) {
        this.navigationService.setProgresBarStyleOrStatus(carrierConfig, step, true, true);
      }
    }
  }

  public resetClaimFormFieldsAndStoreSession(carrierConfig: ConfigurationModel | undefined = undefined) {
    if (carrierConfig) {
      /* Before resetting the claimForms copy the document types */
      let tempdocsArray: SupportingDocumentType[] = [];
      const tempDocumentTypes: SupportingDocumentType[] = Object.assign([], this.supportingDocumentForm.get('copyDocumentTypes')?.value);
      this.claimForm.reset();
      this.supportingDocumentForm.get('copyDocumentTypes')?.setValue(tempDocumentTypes);
      tempDocumentTypes.forEach(doc => tempdocsArray.push(Object.assign({}, doc)))
      this.supportingDocumentForm.get('documentTypes')?.setValue(tempdocsArray);
      this.customizeDocumentTypes(carrierConfig, this.supportingDocumentForm);
      this.supportingDocumentForm.get('uploadedTotalFileSize')?.setValue(0);

      this.consignmetInformationForm.get('claimType')?.setValue('');
      this.updateDamageLocationBasedOnClaimType(carrierConfig);
      this.contactInformationForm.get('claimantRole')?.setValue('');
      this.contactInformationForm.get('claimant.claimantPartyType')?.setValue('');
      this.contactInformationForm.get('claimant.claimantPartyType')?.setValue('');
      this.bankInformationDetailForm.get('isInternationalClaimant')?.setValue('false');

      this.welcomeForm.get('isPreviousClaimant')?.setValue('0');
      this.welcomeForm.get('isDraftClaim')?.setValue('0');
      this.consignmetInformationForm.get('isSalvageRequired')?.setValue('0');
      this.claimInformationForm.get('isGoodsRepairable')?.setValue('0');
      this.sender.get('state')?.setValue('');
      this.receiver.get('state')?.setValue('');

      this.sender.get('name')?.enable({ onlySelf: true });
      this.sender.get('addressLine1')?.disable({ onlySelf: true });
      this.sender.get('city')?.disable({ onlySelf: true });
      this.sender.get('state')?.disable({ onlySelf: true });
      this.sender.get('postalCode')?.disable({ onlySelf: true });

      this.receiver.get('name')?.enable({ onlySelf: true });
      this.receiver.get('addressLine1')?.disable({ onlySelf: true });
      this.receiver.get('city')?.disable({ onlySelf: true });
      this.receiver.get('state')?.disable({ onlySelf: true });
      this.receiver.get('postalCode')?.disable({ onlySelf: true });

      this.welcomeForm.get('carrier')?.setValue({ code: carrierConfig?.prefix });

      /*sets the validation for the goods received date*/
      this.setGoodsReceivedDate(this.consignmetInformationForm.get('claimType')?.value, carrierConfig);
      this.updateFormControlValidation();
      this.setClaimFormDataToSessionStorage(carrierConfig.prefix);
      this.commonService.isDraftClaim$.next(false);
    }

  }

  /**
  * update claim form validation by iterating through each field.
  * */
  public updateFormControlValidation() {
    this.claimForm.controls.forEach(group => {
      let formGroup = group as UntypedFormGroup;
      Object.keys(formGroup.controls).forEach(key => {
        formGroup.get(key)?.updateValueAndValidity();
      });
    })
  }

  public getCarrierSpecificDefaultValues(carrierConfig: ConfigurationModel, controlName: string): object | null {

    let customField = carrierConfig.customFields?.filter(field => field.fieldName == controlName && field.defaultValues)[0];
    if (customField) {
      return customField.defaultValues as { id: number, name: string }[];
    }
    return null
  }

  /**
   * Submits the claim and its attachments to the server.
   * @param carrierConfig
   */
  public submitClaim(carrierConfig: ConfigurationModel | undefined = undefined) {
    this.commonService.isLoading$.next(true);
    this.updateStreetAddress()
    this.mapClaimantInforBasedOnRole();
    this.updateClaimantType();
    let claimDto = this.mapClaimFormToJSON(this.claimForm);
    if (environment.showConsoleMessages) {
      console.log(claimDto);
    }

    let attachments = this.supportingDocumentForm.get('attachments')?.value
    this.appService.submitClaim(claimDto, attachments).subscribe(
      (response) => {
        this.commonService.isLoading$.next(false);
        this._modalCallBackMethod ?
          this._modalCallBackMethod('Successful!', response.Message).result.then(value => {
            this.commonService.isUploaded$.next(false);
            window.location.reload();
            this.resetClaimForm(carrierConfig);
            this.clearSessionStorage();
          })
          : console.log(response)
      },
      (errorResponse) => {
        this.commonService.isLoading$.next(false);
        console.log(errorResponse)
        let errorMessage = '';
        if (errorResponse) {
          if (errorResponse.error) {
            if (errorResponse.error.Message) {
              errorMessage = errorResponse.error.Message;
            }
            if (errorResponse.error.ExceptionMessage) {
              errorMessage = `${errorMessage} <br/> ${errorResponse.error.ExceptionMessage}`;
            }
            if (!errorResponse.error.Message && !errorResponse.error.ExceptionMessage) {
              errorMessage = errorResponse.error;
            }
          }
          if (errorResponse.message) {
            errorMessage = `${errorMessage} <br/>  ${errorResponse.message}`;
          }

        } else {
          errorMessage = "No error response";
        }

        this._modalCallBackMethod ? this._modalCallBackMethod('ERROR', errorMessage) : console.log(errorResponse);
      }
    );
  }

  /*------------------------------- Aramex Related Methods and Properties ---------------------------------------- */

  private _amxCustomerinformationForm = this.formRegistry.registry[Constant.claimFormComponentNames.AmxCustomerInformation];

  private _amxPurchasingCustomerForm = this.formRegistry.registry[Constant.claimFormComponentNames.AmxPurchasingCustomer];

  private _amxSenderForm = this.formRegistry.registry[Constant.claimFormComponentNames.AmxSenderDetails];

  private _amxReceiverForm = this.formRegistry.registry[Constant.claimFormComponentNames.AmxReceiverDetails];

  private _amxSupportingDocumentForm = this.formRegistry.registry[Constant.claimFormComponentNames.AmxRequiredSupportingInformation];

  private _amxParcelDetailsForm = this.formRegistry.registry[Constant.claimFormComponentNames.AmxParcelDetails];

  private _amxfurtherDetailsForm = this.formRegistry.registry[Constant.claimFormComponentNames.AmxFurtherDetails];

  private _amxWelcomeForm = this.formRegistry.registry[Constant.claimFormComponentNames.AMXWelcome];

  private _amxPaymentForm = this.formRegistry.registry[Constant.claimFormComponentNames.AmxPayment];

  private _amxTermsAndConditionsForm = this.formRegistry.registry[Constant.claimFormComponentNames.AmxTermsAndConditions];

  private _amxPreviousClaimForm = this.formRegistry.registry['AmxPreviousClaim'];

  private _amxExcessLabelsForm = this.formRegistry.registry[Constant.claimFormComponentNames.AmxExcessLabel];

  public get amxCustomerinformationForm() {
    return this._amxCustomerinformationForm;
  }
  public get amxPurchasingCustomerForm() {
    return this._amxPurchasingCustomerForm;
  }
  public get amxSenderForm() {
    return this._amxSenderForm;
  }
  public get amxReceiverForm() {
    return this._amxReceiverForm;
  }
  public get amxSupportingDocumentForm() {
    return this._amxSupportingDocumentForm;
  }
  public get amxParcelDetailsForm() {
    return this._amxParcelDetailsForm;
  }
  public get amxfurtherDetailsForm() {
    return this._amxfurtherDetailsForm;
  }
  public get amxTermsAndConditionsForm() {
    return this._amxTermsAndConditionsForm;
  }
  public get amxWelcomeForm() {
    return this._amxWelcomeForm;
  }
  public get amxPaymentForm() {
    return this._amxPaymentForm;
  }
  public get amxPreviousForm() {
    return this._amxPreviousClaimForm;
  }

  public get amxExcessLabelForm() {
    return this._amxExcessLabelsForm;
  }

  private _amxClaimForm = this.formBuilder.array(
    [this.amxCustomerinformationForm,
    this.amxPurchasingCustomerForm,
    this.amxSenderForm,
    this.amxReceiverForm,
    this.amxSupportingDocumentForm,
    this.amxParcelDetailsForm,
    this.amxfurtherDetailsForm,
    this.amxTermsAndConditionsForm,
    this.amxWelcomeForm,
    this.amxPaymentForm,
    this.amxExcessLabelForm
    ])

  public get amxClaimForm() {
    return this._amxClaimForm;
  }

  public async geocodeAddressAsync(controls: any) {
    /* set fields that are not displayed to empty before assigning values */
    controls?.get('street')?.setValue('');
    controls?.get('country')?.setValue('');
    var searchResults: ({ error: boolean, message: string } | null) = { error: false, message: '' };
     this.apiLoaded.subscribe(async () => {
      const geocoder = new google.maps.Geocoder();
      const address = controls?.get('addressLine1')?.value
      const city = controls?.get('city')?.value;
      const postalCode = controls?.get('postalCode')?.value;
      let fullAddress;
      if (address || city || postalCode) {
        fullAddress = (`${address} ${city} ${postalCode}`).replace('&', 'and');
      }
      try {
        await geocoder.geocode({ 'address': fullAddress }, function (results, status) {
          // callbackFunction(results, status);
          if (status == "OK" && results) {
            const resolvedAddress = results[0];
            controls?.get('address')?.setValue(resolvedAddress.formatted_address, { onlyself: true });
            controls?.get('latitude')?.setValue(resolvedAddress.geometry.location.lat(), { onlyself: true });
            controls?.get('longitude')?.setValue(resolvedAddress.geometry.location.lng(), { onlyself: true });
            for (let i = 0; i <= resolvedAddress.address_components?.length; i++) {
              let addressType = resolvedAddress.address_components[i]?.types[0];
              switch (addressType) {
                case 'administrative_area_level_1':
                  controls?.get('state')?.setValue(resolvedAddress.address_components[i]['short_name'], { onlyself: true });
                  break;
                case 'country':
                  controls?.get('country')?.setValue(resolvedAddress.address_components[i]['long_name'], { onlyself: true });
                  break;
                case 'route':
                  controls?.get('street')?.setValue(resolvedAddress.address_components[i]['short_name'], { onlyself: true });
              }
            }
            if (!controls?.get('street')?.value) {
              var addressComponents = resolvedAddress.address_components;
              var result = addressComponents.filter((f: any) => {
                var value = f.types?.filter((c: any) => { if (c === 'premise') return c; });
                if (value?.length > 0) {
                  return value;
                }
              });
              if (result && result.length > 0) {
                controls?.get('street')?.setValue(result[0]['short_name']);
              }
            }
            searchResults = { error: false, message: status };
          } else {
            searchResults = { error: true, message: status };
          }
        });
      } catch (e) {
        if (environment.showConsoleMessages) {
          console.log(e);
        }
      }

    });
    return searchResults;
  }

  private get amxClaimantRole() {
    return this.amxWelcomeForm?.controls?.claimantRole;
  }

  private get amxSender() {
    return this.amxSenderForm?.controls.sender;
  }

  private get amxReceiver() {
    return this.amxReceiverForm?.controls.receiver;
  }

  private get amxPurchasingCustomer() {
    return this.amxPurchasingCustomerForm?.controls.purchasingCustomer;
  }

  private get amxCustomerInfor() {
    return this.amxCustomerinformationForm?.controls;
  }
  private get amxfurtherDetails() {
    return this.amxfurtherDetailsForm?.controls;
  }



  public setAmxPreviouseClaimData(claim: any, isReset: boolean = false) {

    const amxClaimObject = this.mapClaimFormData(claim);
    const amxClaimantObject = this.mapClaimFormData(claim, 'claimant');
    if (amxClaimObject && amxClaimantObject) {
      /*Set Previouse claim claimant role */
      const claimantRole = Constant.claimantRole.filter(cr => cr.id == parseInt(amxClaimObject['claimantRole']))[0]
      this.amxWelcomeForm.get('claimantRole')?.setValue(claimantRole, { onlyself: true });

      if (this.amxClaimantRole && this.amxClaimantRole.value.id > -1) {
        switch (this.amxClaimantRole.value.id) {
          case Constant.claimantRole[0].id:
            {
              this.amxSender.patchValue(amxClaimantObject, { onlyself: true });
              const senderGst = amxClaimantObject['isRegisteredForGst'] ? '1' : '0';
              this.amxCustomerInfor.sender.get('isRegisteredForGst')?.setValue(senderGst, { onlyself: true });
              this.amxCustomerInfor.sender.get('abnNumber')?.setValue(amxClaimantObject['abnNumber'], { onlyself: true });
              const senderIsClaimantWinery = amxClaimantObject['isClaimantWinery'] ? '1' : '0';
              this.amxfurtherDetails?.sender?.get('isClaimantWinery')?.setValue(senderIsClaimantWinery, { onlyself: true });
              this.amxCustomerInfor.sender.get('claimantPartyType')?.setValue(amxClaimantObject['claimantPartyType'], { onlyself: true });
              break;
            }
          case Constant.claimantRole[1].id: {
            this.amxReceiver.patchValue(amxClaimantObject, { onlyself: true });
            const receiverGst = amxClaimantObject['isRegisteredForGst'] ? '1' : '0';
            this.amxCustomerInfor.receiver.get('isRegisteredForGst')?.setValue(receiverGst, { onlyself: true });
            this.amxCustomerInfor.receiver.get('abnNumber')?.setValue(amxClaimantObject['abnNumber'], { onlyself: true });
            const receiverIsClaimantWinery = amxClaimantObject['isClaimantWinery'] ? '1' : '0';
            this.amxfurtherDetails?.receiver?.get('isClaimantWinery')?.setValue(receiverIsClaimantWinery, { onlyself: true });
            this.amxCustomerInfor.receiver.get('claimantPartyType')?.setValue(amxClaimantObject['claimantPartyType'], { onlyself: true });
            break;
          }
          case Constant.claimantRole[2].id: {
            this.amxPurchasingCustomer.patchValue(amxClaimantObject, { onlyself: true });
            const purchasingCustomerGst = amxClaimantObject['isRegisteredForGst'] ? '1' : '0';
            this.amxCustomerInfor.purchasingCustomer.get('isRegisteredForGst')?.setValue(purchasingCustomerGst, { onlyself: true });
            this.amxCustomerInfor.purchasingCustomer.get('abnNumber')?.setValue(amxClaimantObject['abnNumber'], { onlyself: true });
            const pcIsClaimantWinery = amxClaimantObject['isClaimantWinery'] ? '1' : '0';
            this.amxfurtherDetails?.purchasingCustomer?.get('isClaimantWinery')?.setValue(pcIsClaimantWinery, { onlyself: true });
            this.amxCustomerInfor.purchasingCustomer.get('claimantPartyType')?.setValue(amxClaimantObject['claimantPartyType'], { onlyself: true });
            break;
          }
        }
      }
      if (isReset) {
        this.amxCustomerInfor.customerType?.setValue('3', { onlyself: true });
      }
      else {
        this.mapCustomerType(amxClaimantObject['claimantPartyType']);
      }

      this.amxPaymentForm.get('accountName')?.setValue(amxClaimObject['accountName'], { onlyself: true });
      this.amxPaymentForm.get('accountAddress')?.setValue(amxClaimObject['accountAddress'], { onlyself: true });
      this.amxPaymentForm.get('nameOfBank')?.setValue(amxClaimObject['nameOfBank'], { onlyself: true });
      this.amxPaymentForm.get('bankAddress')?.setValue(amxClaimObject['bankAddress'], { onlyself: true });
      this.amxPaymentForm.get('accountNumber')?.setValue(amxClaimObject['accountNumber'], { onlyself: true });
      this.amxPaymentForm.get('bankABA')?.setValue(amxClaimObject['bankABA'], { onlyself: true });
      this.amxPaymentForm.get('bankSWIFT')?.setValue(amxClaimObject['bankSWIFT'], { onlyself: true });
      this.amxPaymentForm.get('bsbNumber')?.setValue(amxClaimObject['bsbNumber'], { onlyself: true });

      if (claim?.accountCurrency?.id == 0) {
        this.amxPaymentForm.get('accountCurrency')?.setValue('', { onlyself: true });
      }
      else {
        this.amxPaymentForm.get('accountCurrency')?.setValue(claim?.accountCurrency, { onlyself: true });
      }

      //this.amxSupportingDocumentForm.reset();
      //this.amxSupportingDocumentForm.updateValueAndValidity();

      if (amxClaimObject['isInternationalClaimant']) {
        this.amxCustomerInfor.isInternationalClaimantInitially?.setValue('true', { onlyself: true });
        this.amxCustomerInfor.isInternationalClaimant?.setValue('true', { onlyself: true });
      } else {
        this.amxCustomerInfor.isInternationalClaimant?.setValue('false', { onlyself: true });
        this.amxCustomerInfor.isInternationalClaimantInitially?.setValue('false', { onlyself: true });
      }

      this.amxSupportingDocumentForm.get('attachments')?.setValue([], { onlyself: true });
      this.resetRequiredSupportingDocumentForm(this.amxSupportingDocumentForm);
      this.amxSupportingDocumentForm.updateValueAndValidity();
      this.setRequiredDocumentsValidators();
      this.setBsbValidateAmx();
      this.setCustomerinforValidators();
      //this.mapCustomerTypeToClaimForm();
      this.setPaymentValidators();
      this.setPurchasingCustomerValidators();
      this.amxClaimForm.updateValueAndValidity();
    }
  }

  public setAmxClaimData(claim: any, storedData: boolean = false) {
    const date = new Date();
    const formatedDate = this.formatDate(date, true);

    this.appService.getIpAddress().subscribe(result => {
      this.amxTermsAndConditionsForm.get('ipAddress')?.setValue(result.ip, { onlyself: true });
    }, error => {
      this.amxTermsAndConditionsForm.get('ipAddress')?.setValue('0.0.0.0', { onlyself: true });
    });
    this.amxTermsAndConditionsForm.get('date')?.setValue(formatedDate, { onlyself: true });

    this.amxTermsAndConditionsForm.get('browser')?.setValue(this.getBrowserType(), { onlyself: true });
    this.amxTermsAndConditionsForm.get('browserVersion')?.setValue(this.getBrowserVersion(), { onlyself: true });

    const amxClaimObject = this.mapClaimFormData(claim);
    const senderObject = this.mapClaimFormData(claim, 'sender');
    const receiverObject = this.mapClaimFormData(claim, 'receiver');
    const purchasingCustomerObject = this.mapClaimFormData(claim, 'purchasingCustomer');
    if (amxClaimObject) {

      const claimantRole = Constant.claimantRole.filter(cr => cr.id == parseInt(amxClaimObject['claimantRole']))[0]
      this.amxWelcomeForm.get('claimantRole')?.setValue(claimantRole, { onlyself: true });
      this.amxWelcomeForm.get('claimNumber')?.setValue(amxClaimObject['claimNumber'], { onlyself: true });
      this.amxWelcomeForm.get('isFreightInsureClaimRequired')?.setValue(amxClaimObject['isFreightInsureClaimRequired'], { onlyself: true });
      this.amxWelcomeForm.get('claimId')?.setValue(amxClaimObject['claimId'], { onlyself: true });
      if (amxClaimObject['encodedClaimNumber']) {
        this.amxWelcomeForm.get('encodedClaimNumber')?.setValue(amxClaimObject['encodedClaimNumber'], { onlyself: true });
      }

      /*Replace null values for the cost of excess labels with 0 */
      var excessLabels: any = amxClaimObject['excessLabels'];
      for (var i = 0; i < excessLabels.length; i++) {
        if (excessLabels[i]['excessLabel'] && !excessLabels[i]['cost']) {
          excessLabels[i]['cost'] = 0;
        }
      }

      this.amxExcessLabelForm.patchValue(amxClaimObject);
      if (this.amxExcessLabelForm.get('excessLabels')?.value && this.amxExcessLabelForm.get('excessLabels')?.value.length > 0) {
        this.amxExcessLabelForm.get('enableLabels')?.setValue(1);
      }
      this.amxPaymentForm.patchValue(amxClaimObject);
      this.amxParcelDetailsForm.patchValue(amxClaimObject);
      this.amxfurtherDetailsForm.patchValue(amxClaimObject);
      this.amxfurtherDetailsForm.get('claimValue')?.updateValueAndValidity();
      const claimType = Constant.claimType.filter(cr => cr.id == parseInt(amxClaimObject['claimType']))[0]
      this.amxfurtherDetailsForm.get('claimType')?.setValue(claimType, { onlyself: true });
      if (senderObject) {
        this.amxSender.patchValue(senderObject, { onlyself: true });
        const senderGst = senderObject['isRegisteredForGst'] ? '1' : '0';
        this.amxCustomerInfor.sender.get('isRegisteredForGst')?.setValue(senderGst, { onlyself: true });
        this.amxCustomerInfor.sender.get('abnNumber')?.setValue(senderObject['abnNumber'], { onlyself: true });
        const senderIsClaimantWinery = senderObject['isClaimantWinery'] ? '1' : '0';
        this.amxfurtherDetails?.sender?.get('isClaimantWinery')?.setValue(senderIsClaimantWinery, { onlyself: true });
        this.amxCustomerInfor.sender.get('claimantPartyType')?.setValue(senderObject['claimantPartyType'], { onlyself: true });
      }

      if (receiverObject) {
        this.amxReceiver.patchValue(receiverObject, { onlyself: true });
        const receiverGst = receiverObject['isRegisteredForGst'] ? '1' : '0';
        this.amxCustomerInfor.receiver.get('isRegisteredForGst')?.setValue(receiverGst, { onlyself: true });
        this.amxCustomerInfor.receiver.get('abnNumber')?.setValue(receiverObject['abnNumber'], { onlyself: true });
        const receiverIsClaimantWinery = receiverObject['isClaimantWinery'] ? '1' : '0';
        this.amxfurtherDetails?.receiver?.get('isClaimantWinery')?.setValue(receiverIsClaimantWinery, { onlyself: true });
        this.amxCustomerInfor.receiver.get('claimantPartyType')?.setValue(receiverObject['claimantPartyType'], { onlyself: true });
      }

      if (purchasingCustomerObject) {
        this.amxPurchasingCustomer.patchValue(purchasingCustomerObject, { onlyself: true });
        const purchasingCustomerGst = purchasingCustomerObject['isRegisteredForGst'] ? '1' : '0';
        this.amxCustomerInfor.purchasingCustomer.get('isRegisteredForGst')?.setValue(purchasingCustomerGst, { onlyself: true });
        this.amxCustomerInfor.purchasingCustomer.get('abnNumber')?.setValue(purchasingCustomerObject['abnNumber'], { onlyself: true });
        const pcIsClaimantWinery = purchasingCustomerObject['isClaimantWinery'] ? '1' : '0';
        this.amxfurtherDetails?.purchasingCustomer?.get('isClaimantWinery')?.setValue(pcIsClaimantWinery, { onlyself: true });
        this.amxCustomerInfor.purchasingCustomer.get('claimantPartyType')?.setValue(purchasingCustomerObject['claimantPartyType'], { onlyself: true });
      }
      this.amxReceiverForm.get('receiverComment')?.setValue(amxClaimObject['receiverComment'], { onlyself: true });
      this.amxPurchasingCustomerForm.get('purchasingCustomerComment')?.setValue(amxClaimObject['purchasingCustomerComment'], { onlyself: true });
      this.amxSenderForm.get('senderComment')?.setValue(amxClaimObject['senderComment'], { onlyself: true });
      this.amxCustomerinformationForm?.get('claimantComment')?.setValue(amxClaimObject['claimantComment'], { onlyself: true });

      const dateTimeSent = Utility.convertDateStringToDate(claim.dateTimeSent);
      this.amxParcelDetailsForm.get('dateTimeSent')?.setValue(dateTimeSent, { onlyself: true });


      const damagedInTransitWine = amxClaimObject['isGoodsDamagedInTransitWine'] ? '1' : '0';
      this.amxfurtherDetailsForm.get('isGoodsDamagedInTransitWine')?.setValue(damagedInTransitWine, { onlyself: true });

      if (claim?.accountCurrency?.id == 0) {
        this.amxPaymentForm.get('accountCurrency')?.setValue('', { onlyself: true });
      }
      else {
        this.amxPaymentForm.get('accountCurrency')?.setValue(claim?.accountCurrency, { onlyself: true });
      }

      if (claim?.claimCurrency?.id == 0) {
        this.amxfurtherDetailsForm.get('claimCurrency')?.setValue('', { onlyself: true });
      }
      else {
        this.amxfurtherDetailsForm.get('claimCurrency')?.setValue(claim?.claimCurrency, { onlyself: true });
      }
      const packageType = claim.packageType;
      this.amxParcelDetailsForm.get('packageType')?.setValue(packageType, { disabled: true });

      const isGoodsRepairable = amxClaimObject['isGoodsRepairable'] ? 'true' : 'false';
      this.amxfurtherDetailsForm.get('isGoodsRepairable')?.setValue(isGoodsRepairable, { onlyself: true });
      const isSalvageRequired = amxClaimObject['isSalvageRequired'] ? 'true' : 'false';
      this.amxfurtherDetailsForm.get('isSalvageRequired')?.setValue(isSalvageRequired, { onlyself: true });

      this.amxfurtherDetailsForm.get('damagedGoodsLocation')?.setValue(amxClaimObject['damagedGoodsLocation'], { onlyself: true });
      const isAdditionalLabelsDispatched = amxClaimObject['isAdditionalLabelsDispatched'] ? 'true' : 'false';
      this.amxParcelDetailsForm.get('isAdditionalLabelsDispatched')?.setValue(isAdditionalLabelsDispatched, { onlyself: true });
      if (storedData) {
        let value = amxClaimObject['customerType'];
        this.amxCustomerInfor.customerType?.setValue(value, { onlyself: true });
      } else {
        this.amxCustomerInfor.customerType?.setValue('3', { onlyself: true });
      }
      if (amxClaimObject['isInternationalClaimant']) {
        this.amxCustomerInfor.isInternationalClaimant?.setValue('true', { onlyself: true });
        if (storedData) {
          this.amxCustomerInfor.isInternationalClaimantInitially?.setValue('false', { onlyself: true });
        } else {
          this.amxCustomerInfor.isInternationalClaimantInitially?.setValue('true', { onlyself: true });
        }
      } else {
        this.amxCustomerInfor.isInternationalClaimant?.setValue('false', { onlyself: true });
        this.amxCustomerInfor.isInternationalClaimantInitially?.setValue('false', { onlyself: true });
      }
      this.setRequiredDocumentsValidators();
      this.setBsbValidateAmx();
      this.setCustomerinforValidators();
      //this.mapCustomerTypeToClaimForm();
      this.setFurtherDetailsValidators();
      this.setPaymentValidators();
      this.setPurchasingCustomerValidators();
      this.amxClaimForm.updateValueAndValidity();
    }
  }

  public mapCustomerType(customerType: any) {
    let value;
    if (customerType == 0) // individual
    {
      value = '0';
    }
    else if (customerType == 1) {
      value = '1';
    }
    else if (customerType == 2) {
      value = '1';
    }
    else if (customerType == 3) {
      value = '2';
    }
    else if (customerType == 4) {
      value = '3';
    }
    this.amxCustomerInfor?.customerType?.setValue(value, { onlyself: true });
  }
  public mapCustomerTypeToClaimForm() {
    const claimantRole = this.amxWelcomeForm.get('claimantRole')?.value.value;
    const customerType = this.amxCustomerInfor.customerType?.value;
    if (claimantRole == 'Sender') {
      if (customerType == '0') // individual
      {
        this.amxCustomerInfor.sender.get('abnNumber')?.setValue('', { onlyself: true });
        this.amxCustomerInfor.sender.get('claimantPartyType')?.setValue(0, { onlyself: true });
      }
      else if (customerType == '1') {
        this.amxCustomerInfor.sender.get('claimantPartyType')?.setValue(2, { onlyself: true });
      }
      else if (customerType == '2') {
        this.amxCustomerInfor.sender.get('claimantPartyType')?.setValue(3, { onlyself: true });
      }
      else if (customerType == '3') {
        this.amxCustomerInfor.sender.get('claimantPartyType')?.setValue(4, { onlyself: true });
      }
    }
    else if (claimantRole == 'Receiver') {
      if (customerType == '0') // individual
      {
        this.amxCustomerInfor.receiver.get('abnNumber')?.setValue('', { onlyself: true });
        this.amxCustomerInfor.receiver.get('claimantPartyType')?.setValue(0, { onlyself: true });
      }
      else if (customerType == '1') {
        this.amxCustomerInfor.receiver.get('claimantPartyType')?.setValue(2, { onlyself: true });
      }
      else if (customerType == '2') {
        this.amxCustomerInfor.receiver.get('claimantPartyType')?.setValue(3, { onlyself: true });
      }
      else if (customerType == '3') {
        this.amxCustomerInfor.receiver.get('claimantPartyType')?.setValue(4, { onlyself: true });
      }
    }
    else if (claimantRole == 'Third Party') {
      if (customerType == '0') // individual
      {
        this.amxCustomerInfor.purchasingCustomer.get('abnNumber')?.setValue('', { onlyself: true });
        this.amxCustomerInfor.purchasingCustomer.get('claimantPartyType')?.setValue(0, { onlyself: true });
      }
      else if (customerType == '1') {
        this.amxCustomerInfor.purchasingCustomer.get('claimantPartyType')?.setValue(2, { onlyself: true });
      }
      else if (customerType == '2') {
        this.amxCustomerInfor.purchasingCustomer.get('claimantPartyType')?.setValue(3, { onlyself: true });
      }
      else if (customerType == '3') {
        this.amxCustomerInfor.purchasingCustomer.get('claimantPartyType')?.setValue(4, { onlyself: true });
      }
    }
  }
  /**
   * Check if an AMX claim with the same claim number is stored in the session storage.
   * @param claimNumber claim number to match.
   */
  public checkSessionForAMXClaimForm(claimNumber: string): boolean {
    const claimFormJSON = this.commonService.getCacheObject(this._claimFormSessionKey);
    if (claimFormJSON) {
      let claimFormJSONObject = JSON.parse(claimFormJSON);
      if (claimFormJSONObject.claimNumber == claimNumber) {
        return true
      }

    }
    return false;
  }

  public submitAmxClaim() {
    this.commonService.isLoading$.next(true);
    this.mapCustomerTypeToClaimForm();
    if (this.amxClaimantRole && this.amxClaimantRole?.value?.id > -1) {
      switch (this.amxClaimantRole.value.id) {
        case Constant.claimantRole[0].id:
          {
            this.amxCustomerInfor.receiver.get('abnNumber')?.setValue('', { onlyself: true });
            this.amxCustomerInfor.purchasingCustomer.get('abnNumber')?.setValue('', { onlyself: true });
            break;
          }
        case Constant.claimantRole[1].id: {
          this.amxCustomerInfor.sender.get('abnNumber')?.setValue('', { onlyself: true });
          this.amxCustomerInfor.purchasingCustomer.get('abnNumber')?.setValue('', { onlyself: true });
          break;
        }
        case Constant.claimantRole[2].id: {
          this.amxCustomerInfor.sender.get('abnNumber')?.setValue('', { onlyself: true });
          this.amxCustomerInfor.receiver.get('abnNumber')?.setValue('', { onlyself: true });
          break;
        }
      }
    }

    /* Map the phone number fields */
    if (this.amxSender.get('officeNumber')?.value) {
      this.amxSender.get('phone')?.setValue(this.amxSender.get('officeNumber')?.value);
    }
    if (this.amxReceiver.get('officeNumber')?.value) {
      this.amxReceiver.get('phone')?.setValue(this.amxReceiver.get('officeNumber')?.value);
    }
    if (this.amxPurchasingCustomer.get('officeNumber')?.value) {
      this.amxPurchasingCustomer.get('phone')?.setValue(this.amxPurchasingCustomer.get('officeNumber')?.value);
    }


    if (!this.amxTermsAndConditionsForm.get('ipAddress')?.value || this.amxTermsAndConditionsForm.get('ipAddress')?.value == "") {
      this.amxTermsAndConditionsForm.get('ipAddress')?.setValue('0.0.0.0', { onlyself: true });
    }
    let claimDto = this.mapClaimFormToJSON(this.amxClaimForm);
    if (environment.showConsoleMessages) {
      console.log(claimDto);
    }
    let attachments = this.amxSupportingDocumentForm.get('attachments')?.value




    this.appService.submitAmxClaim(claimDto, attachments).subscribe(
      (response) => {
        this.commonService.isLoading$.next(false);
        this._modalCallBackMethod ?
          this._modalCallBackMethod('Successful!',response.Message).result.then(value => {
            this.commonService.isUploaded$.next(false);
            this.clearSessionStorage();
            window.location.href = 'https://www.aramex.com.au';
          })
          : console.log(response);
      },
      (errorResponse) => {
        this.commonService.isLoading$.next(false);
        console.log(errorResponse)

        let errorMessage = '';
        if (errorResponse) {
          if (errorResponse.error) {
            if (errorResponse.error.Message) {
              errorMessage = errorResponse.error.Message;
            }
            if (errorResponse.error.ExceptionMessage) {
              errorMessage = `${errorMessage} <br/> ${errorResponse.error.ExceptionMessage}`;

            }
            if (!errorResponse.error.Message && !errorResponse.error.ExceptionMessage) {
              errorMessage = errorResponse.error;
            }
          }
          if (errorResponse.message) {
            errorMessage = `${errorMessage} <br/>  ${errorResponse.message}`;
          }

        } else {
          errorMessage = "No error response";
        }

        this._modalCallBackMethod ? this._modalCallBackMethod('ERROR', errorMessage) : console.log(errorResponse);
      }
    );
  }

  public resetAmxClaimForm() {
    const tempDocumentTypes = Object.assign([], this.amxSupportingDocumentForm.get('copyDocumentTypes')?.value);
    this.amxClaimForm.reset();
    this.amxSupportingDocumentForm.get('copyDocumentTypes')?.setValue(tempDocumentTypes);
    this.amxSupportingDocumentForm.get('documentTypes')?.setValue(tempDocumentTypes);
    this.amxSupportingDocumentForm.get('uploadedTotalFileSize')?.setValue(0);
    this.amxClaimForm.updateValueAndValidity();
  }

  public resetRequiredSupportingDocumentForm(supportingDocumentForm: UntypedFormGroup) {
    const tempDocumentTypes: SupportingDocumentType[] = Object.assign([], supportingDocumentForm.get('copyDocumentTypes')?.value);
    let tempDocs: SupportingDocumentType[] = [];
    supportingDocumentForm.reset();
    supportingDocumentForm.get('copyDocumentTypes')?.setValue(tempDocumentTypes);
    tempDocumentTypes.forEach(doc => tempDocs.push(Object.assign({}, doc)))
    supportingDocumentForm.get('documentTypes')?.setValue(tempDocs);
    supportingDocumentForm.get('uploadedTotalFileSize')?.setValue(0);
  }

  public setRequiredDocumentsValidators() {

    if (this.amxCustomerInfor.customerType?.value == '0') {
      if (!this.amxSupportingDocumentForm?.get('attachments')?.value || this.amxSupportingDocumentForm?.get('attachments')?.value?.length <= 0) {
        this.setOrRemoveValidatorsFromDocuments(true);
      } else {
        this.setOrRemoveValidatorsFromDocuments(false);
      }
    }
    else {
      this.setOrRemoveValidatorsFromDocuments(false);
      const attachments: DocumentTypeUploads[] = this.amxSupportingDocumentForm?.get('attachments')?.value;
      if (attachments) {
        var doc = attachments.filter(attachment => attachment.documentType.controlName == 'siFile');
        if (doc && doc.length <= 0) {
          this.amxSupportingDocumentForm?.get('siFile')?.setValidators([Validators.required]);
          this.amxSupportingDocumentForm?.get('siFile')?.updateValueAndValidity({ onlySelf: true });
        }
      } else {
        this.amxSupportingDocumentForm?.get('siFile')?.setValidators([Validators.required]);
        this.amxSupportingDocumentForm?.get('siFile')?.updateValueAndValidity({ onlySelf: true });

      }
    }
    this.amxSupportingDocumentForm?.updateValueAndValidity();

  }

  private setOrRemoveValidatorsFromDocuments(setValidators: boolean) {

    let Keys = Object.keys(this.amxSupportingDocumentForm.controls);
    for (let i = 0; i < Keys.length; i++) {
      if (Keys[i] == 'attachments' || Keys[i] == 'documentType' || Keys[i] == 'documentTypes') {
        continue;
      } else {
        if (setValidators) {
          this.amxSupportingDocumentForm?.get(Keys[i])?.setValidators([Validators.required]);
          this.amxSupportingDocumentForm?.get(Keys[i])?.updateValueAndValidity();
        } else {
          this.amxSupportingDocumentForm?.get(Keys[i])?.clearValidators();
          this.amxSupportingDocumentForm?.get(Keys[i])?.updateValueAndValidity();
        }
      }
    }
  }

  public setBsbValidateAmx() {
    const bsbNumber: string = this.amxPaymentForm.get('bsbNumber')?.value;
    const validationArray: any[] = [Validators.required, Validators.maxLength(6), Validators.minLength(6), this.customValidatorService.numericInputValidator()];
    if (this.amxCustomerInfor?.isInternationalClaimant?.value == 'false') {
      if (bsbNumber && bsbNumber.length == 6) {
        this.commonService.isLoading$.next(true);
        this.appService.getBsbInformation(bsbNumber).subscribe(result => {
          if (result) {
            this.amxPaymentForm.get('bsbInformation')?.setValue(result, { onlySelf: true });
            this.amxPaymentForm.get('bsbNumber')?.clearValidators();
            this.amxPaymentForm.get('bsbNumber')?.setValidators(Validators.compose(validationArray));
            this.amxPaymentForm.get('bsbNumber')?.updateValueAndValidity();
            this.commonService.isLoading$.next(false);
            return;
          }
          else {
            this.amxPaymentForm.get('bsbInformation')?.setValue(null, { onlySelf: true });
            this.amxPaymentForm.get('bsbNumber')?.clearValidators();
            this.amxPaymentForm.get('bsbNumber')?.setValidators(Validators.compose([this.validateBsb(), this.customValidatorService.numericInputValidator()]));
            this.amxPaymentForm.get('bsbNumber')?.updateValueAndValidity();
            this.commonService.isLoading$.next(false);
            return;
          }
        }, error => {
          this.amxPaymentForm.get('bsbInformation')?.setValue(null, { onlySelf: true });
          this.amxPaymentForm.get('bsbNumber')?.clearValidators();
          this.amxPaymentForm.get('bsbNumber')?.setValidators(Validators.compose([this.validateBsb(), this.customValidatorService.numericInputValidator()]));
          this.amxPaymentForm.get('bsbNumber')?.updateValueAndValidity();
          this.commonService.isLoading$.next(false);
          return;
        });
      }
      else {
        this.amxPaymentForm.get('bsbInformation')?.setValue(null, { onlySelf: true });
        this.amxPaymentForm.get('bsbNumber')?.clearValidators();
        this.amxPaymentForm.get('bsbNumber')?.setValidators(Validators.compose(validationArray));
        this.amxPaymentForm.get('bsbNumber')?.updateValueAndValidity();
        return;
      }
    }
    else {
      this.amxPaymentForm.get('bsbNumber')?.clearValidators();
      this.amxPaymentForm.get('bsbNumber')?.updateValueAndValidity();
      return;
    }
  }
  validateBsb(): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: boolean | string } | null => {
      if (!control) {
        return null;
      }
      if (!this.amxPaymentForm.get('bsbInformation')?.value) {
        return { invalidBSB: true };
      }
      return null;
    }
  }

  public setCustomerinforValidators() {
    this.amxCustomerInfor.sender?.get('abnNumber')?.updateValueAndValidity();
    this.amxCustomerInfor.receiver?.get('abnNumber')?.updateValueAndValidity();
    this.amxCustomerInfor.purchasingCustomer?.get('abnNumber')?.updateValueAndValidity();
    if (this.amxCustomerInfor.customerType?.value != '0' && this.amxCustomerInfor?.isInternationalClaimant?.value == 'false') {
      if (this.amxClaimantRole?.value?.value == 'Sender') {
        this.amxCustomerInfor?.sender?.get('abnNumber')?.setValidators([this.customValidatorService.numericInputValidator(), Validators.required, Validators.minLength(11), Validators.maxLength(11)]);
        this.amxCustomerInfor?.receiver?.get('abnNumber')?.clearValidators();
        this.amxCustomerInfor?.purchasingCustomer?.get('abnNumber')?.clearValidators();
      }
      else if (this.amxClaimantRole?.value?.value == 'Receiver') {
        this.amxCustomerInfor?.receiver?.get('abnNumber')?.setValidators([this.customValidatorService.numericInputValidator(), Validators.required, Validators.minLength(11), Validators.maxLength(11)]);
        this.amxCustomerInfor?.sender?.get('abnNumber')?.clearValidators();
        this.amxCustomerInfor?.purchasingCustomer?.get('abnNumber')?.clearValidators();
      }
      else if (this.amxClaimantRole?.value?.value == 'Third Party') {
        this.amxCustomerInfor?.purchasingCustomer?.get('abnNumber')?.setValidators([this.customValidatorService.numericInputValidator(), Validators.required, Validators.minLength(11), Validators.maxLength(11)]);
        this.amxCustomerInfor?.sender?.get('abnNumber')?.clearValidators();
        this.amxCustomerInfor?.receiver?.get('abnNumber')?.clearValidators();
      }
    }
    else {
      this.amxCustomerInfor?.sender?.get('abnNumber')?.clearValidators();
      this.amxCustomerInfor?.receiver?.get('abnNumber')?.clearValidators();
      this.amxCustomerInfor?.purchasingCustomer?.get('abnNumber')?.clearValidators();
    }
    this.amxCustomerInfor.sender?.get('abnNumber')?.updateValueAndValidity();
    this.amxCustomerInfor.receiver?.get('abnNumber')?.updateValueAndValidity();
    this.amxCustomerInfor.purchasingCustomer?.get('abnNumber')?.updateValueAndValidity();
    this.amxCustomerInfor?.sender?.updateValueAndValidity();
    this.amxCustomerInfor?.receiver?.updateValueAndValidity();
    this.amxCustomerInfor?.purchasingCustomer?.updateValueAndValidity();
  }

  public setFurtherDetailsValidators() {
    if (this.amxCustomerInfor?.isInternationalClaimant?.value == 'true') {
      this.amxfurtherDetailsForm.get('claimCurrency')?.setValidators([Validators.required]);
    }
    else if (this.amxCustomerInfor?.isInternationalClaimant?.value == 'false') {
      this.amxfurtherDetailsForm.get('claimCurrency')?.clearValidators();
    }
    if (this.amxfurtherDetailsForm.get('claimType')?.value?.id == '0') {
      this.amxfurtherDetailsForm.get('damagedGoodsLocation')?.setValidators([Validators.required]);
      this.amxfurtherDetailsForm.get('damagedGoodsLocation')?.updateValueAndValidity();
    }
    else {
      this.amxfurtherDetailsForm.get('damagedGoodsLocation')?.clearValidators()
    }
    this.amxfurtherDetailsForm.get('damagedGoodsLocation')?.updateValueAndValidity();
    this.amxfurtherDetailsForm.get('claimCurrency')?.updateValueAndValidity();
    this.amxfurtherDetailsForm.updateValueAndValidity();
  }

  public setPaymentValidators() {
    if (this.amxCustomerInfor?.isInternationalClaimant?.value == 'true') {
      this.amxPaymentForm?.get('accountAddress')?.setValidators([Validators.required]);
      this.amxPaymentForm?.get('bankAddress')?.setValidators([Validators.required]);
      this.amxPaymentForm?.get('bankABA')?.setValidators(Validators.compose([this.customValidatorService.numericInputValidator(), Validators.required, Validators.maxLength(9), Validators.minLength(9)]));
      this.amxPaymentForm?.get('bankSWIFT')?.setValidators(Validators.compose([this.customValidatorService.charactersAndNumbersOnlyValidator(), Validators.required, Validators.maxLength(11), Validators.minLength(8)]));
      this.amxPaymentForm?.get('accountCurrency')?.setValidators([Validators.required]);
      //this.myForm?.bsbNumber?.clearValidators();
    }
    else if (this.amxCustomerInfor?.isInternationalClaimant?.value == 'false') {
      //this.amxPaymentForm?.get('bsbNumber')?.setValidators(Validators.compose(this.validationArray));
      this.amxPaymentForm?.get('accountAddress')?.clearValidators();
      this.amxPaymentForm?.get('bankAddress')?.clearValidators();
      this.amxPaymentForm?.get('bankABA')?.clearValidators();
      this.amxPaymentForm?.get('bankSWIFT')?.clearValidators();
      this.amxPaymentForm?.get('accountCurrency')?.clearValidators();
    }
    this.amxPaymentForm?.get('accountCurrency')?.updateValueAndValidity();
    this.amxPaymentForm?.updateValueAndValidity();
  }
  public setPurchasingCustomerValidators() {
    if (this.amxWelcomeForm.get('claimantRole')?.value?.value == 'Third Party') {
      this.amxPurchasingCustomer.get('name')?.setValidators(Validators.required);
      this.amxPurchasingCustomer.get('name')?.enable();
      this.amxPurchasingCustomer.get('officeNumber')?.setValidators(Validators.compose([this.customValidatorService.numericInputValidator(), Validators.maxLength(10), Validators.minLength(10)]));
      this.amxPurchasingCustomer.get('email')?.setValidators(Validators.compose([Validators.required, this.customValidatorService.customEmailValidator()]));
    }
    else {
      this.amxPurchasingCustomer.get('name')?.clearValidators();
      this.amxPurchasingCustomer.get('name')?.disable();
      this.amxPurchasingCustomer.get('officeNumber')?.clearValidators();
      this.amxPurchasingCustomer.get('email')?.clearValidators();
    }
    this.amxPurchasingCustomer.get('name')?.updateValueAndValidity();
    this.amxPurchasingCustomer.get('officeNumber')?.updateValueAndValidity();
    this.amxPurchasingCustomer.get('email')?.updateValueAndValidity();
    this.amxPurchasingCustomer?.updateValueAndValidity();
  }

  /*--------------------------------- Common Methods --------------------------------------  */

  CancelForm = this.formRegistry.registry[Constant.claimFormComponentNames.Cancel];

  /**
   * Takes in a string parameter and maps Yes,No based on the value.
   * @param value
   */
  public mapRadioButtonValues(value: string): string {
    let result: string = '';
    switch (value) {
      case "false":
      case '0':
        result = 'No';
        break;
      case "true":
      case '1':
        result = 'Yes'
        break;
    }
    return result;
  }

  private formatDate(date: Date, withTime: boolean) {
    var formatedDate;
    if (withTime) {
      formatedDate = (date.getDate()) + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear()) + ' ' + date.toLocaleTimeString();
    }
    else {
      formatedDate = (date.getDate()) + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear());
    }
    return formatedDate;
  }
  /**
   * Retieves and sets the claim form data from the session storage if exists.
   * @param carrierPrefix the parameter is used to determind if the carrier is AMX or warranty.
   */
  public getClaimFormDataFromSessionStorage(carrierConfig: ConfigurationModel) {
    const carrierPrefix = carrierConfig?.prefix;
    const claimFormJSON = this.commonService.getCacheObject(this._claimFormSessionKey);
    if (claimFormJSON) {

      let claimFormJSONObject = JSON.parse(claimFormJSON);
      let claimFormObject = this.mapClaimFormData(claimFormJSONObject, 'ClaimDTO');
      if (carrierPrefix == Constant.carrierPrefix.amxCarrier.toLowerCase()) {
        this.mapAttachmentToClaimForm(carrierPrefix, claimFormObject, this.amxSupportingDocumentForm);
        this.setAmxClaimData(claimFormObject, true);
        this.setRequiredDocumentsValidators();
        return;
      }

      if (claimFormObject) {
        let carrier = this.mapClaimFormData(claimFormObject, 'carrier');
        if (carrier) {
          let carrierCode = carrier['code']
          if (carrierCode != carrierPrefix) {
            return;
          }
        } else {
          return;
        }
        for (let i = 0; i < this.claimForm.controls.length; i++) {
          let formGoup = this.claimForm.get([i]) as UntypedFormGroup;
          let keys = Object.keys(formGoup.controls);
          for (let j = 0; j < keys.length; j++) {
            if (claimFormObject[keys[j]] == null) {
              continue;
            }
            formGoup.get(keys[j])?.patchValue(claimFormObject[keys[j]]);

            if (keys[j] == 'claimant') {
              let claimantObject = this.mapClaimFormData(claimFormObject, 'claimant');
              let customerType = Constant.claimantTypes.filter(ct => claimantObject && ct.id == parseInt(claimantObject['claimantPartyType']))[0];
              formGoup.get(`${keys[j]}.claimantPartyType`)?.setValue(customerType != undefined ? customerType : '', { onlyself: true });
            }
            if (keys[j] == 'claimantRole') {
              let customerType = Constant.claimantRole.filter(cr => claimFormObject && cr.id == parseInt(claimFormObject['claimantRole']))[0];
              formGoup.get(`${keys[j]}`)?.setValue(customerType != undefined ? customerType : '', { onlyself: true });
            }
            if (keys[j] == 'claimType') {
              let claimType;
              let defaultValues: any[] | null = this.getCustomValuesFromCarrerConfig(carrierConfig, 'claimType') as any[];
              if (defaultValues) {
                claimType = defaultValues.filter(ct => claimFormObject && ct.id == parseInt(claimFormObject['claimType']))[0];
              } else {
                claimType = Constant.claimType.filter(ct => claimFormObject && ct.id == parseInt(claimFormObject['claimType']))[0];
              }

              formGoup.get(`${keys[j]}`)?.setValue(claimType != undefined ? claimType : '', { onlyself: true });

              this.setGoodsReceivedDate(formGoup.get(`${keys[j]}`)?.value, carrierConfig);
              this.updateDamageLocationBasedOnClaimType(carrierConfig);
            }
            if ((keys[j] == 'goodsDispatchedDateTime' || keys[j] == 'goodsReceivedDateTime' || keys[j] == 'initialContactDate'
            ) && claimFormObject[keys[j]]) {
              formGoup.get(`${keys[j]}`)?.setValue(Utility.convertDateStringToNgbDate(claimFormObject[keys[j]]), { onlyself: true });
            }
            if (keys[j] == 'isPreviousClaimant') {  //keys[j] == 'isInternationalClaimant' || 
              /* map the radio buttons, from the form object. */
              let value = claimFormObject[keys[j]] ? '1' : '0';
              formGoup.get(`${keys[j]}`)?.setValue(value, { onlyself: true });
            }
            if (keys[j] == 'isInternationalClaimant') {
              /* map the radio buttons, from the form object. */
              let value = claimFormObject[keys[j]] ? 'true' : 'false';
              formGoup.get(`${keys[j]}`)?.setValue(value, { onlyself: true });
            }


          }
        }
      }
    }
  }
  /**
   * Sets the claim form data to the session storage.
   * @param carrierPrefix the parameter is used to determind if the carrier is AMX or warranty.
   */
  public setClaimFormDataToSessionStorage(carrierPrefix: string) {

    let claimFormJSON = '';
    if (carrierPrefix.toLowerCase() == Constant.carrierPrefix.amxCarrier.toLowerCase()) {
      /*Checks if there are draft attachments to be saved in the session storage
       if not ignore the supporting document section entirely*/
      if (this.checkIfSavedAttachmentsExists(this.amxSupportingDocumentForm)) {
        claimFormJSON = this.mapClaimFormToJSON(this.amxClaimForm, "", false, true);
      } else {
        claimFormJSON = this.mapClaimFormToJSON(this.amxClaimForm, 'attachments', true);
      }
      //claimFormJSON = this.mapClaimFormToJSON(this.amxClaimForm); /*'fileIDRT1', true*/
    } else {
      /*Checks if there are draft attachments to be saved in the session storage
       if not ignore the supporting document section entirely*/
      if (this.checkIfSavedAttachmentsExists(this.supportingDocumentForm)) {
        claimFormJSON = this.mapClaimFormToJSON(this.claimForm, "", false, true);
      } else {
        claimFormJSON = this.mapClaimFormToJSON(this.claimForm, 'attachments', true);
      }
    }
    sessionStorage.setItem(this._claimFormSessionKey, claimFormJSON);
  }

  public checkIfSavedAttachmentsExists(form: UntypedFormGroup) {
    var attachments: DocumentTypeUploads[] = form.get('attachments')?.value
    if (attachments && attachments.length > 0) {
      var files = attachments.map(attachment => attachment.files.filter(f => (!(f instanceof File)))).filter(f => f.length > 0);
      if (files.length > 0) {
        return true;
      }
    }
    return false;
  }
  /**
   * clear entire session storage
   */
  public clearSessionStorage() {
    this.commonService.clearCacheObjects();
  }
  /**
   * Pass in a form group and update the value and validity of the controls.
   * @param form FormGroup
   */
  public updateValueandValidityOfFormControls(form: UntypedFormGroup) {
    let keys = Object.keys(form.controls);
    for (let j = 0; j < keys.length; j++) {
      form.get(keys[j])?.updateValueAndValidity();
    }
  }

  /**
 * Transfroms the claimForm to JSON
 * For submission to the server.
 * @param ignoreField - FieldName which will not be included in the JSON string.
 * @param ignoreSection - If true the componet will be ignored an will not be in the JSON string.
 */
  public mapClaimFormToJSON(claimForm: UntypedFormArray, ignoreField: string = '', ignoreSection: boolean = false, mapOnlySavedFiles: boolean = false): string {

    let newObject: { [key: string]: any } = {} //string | {}
    //let claimForm = this.claimForm;

    for (let i = 0; i < claimForm.controls.length; i++) {
      let v = claimForm.get([i]) as UntypedFormGroup
      let controlToIgnore: AbstractControl | null = v.get(ignoreField);
      if (controlToIgnore && ignoreSection) {
        continue;
      }
      let keys = Object.keys(v.controls);
      for (let j = 0; j < keys.length; j++) {
        if (controlToIgnore && keys[j] == ignoreField) {
          continue;
        }
        if (claimForm.controls[i].get(keys[j])?.value) {
          /* AMX claim form has sender and receiver in several details in several form groups,
           * This was done to capture data from all from groups and add them to the newobject. */
          if (newObject[keys[j]]) {
            let originalObject = newObject[keys[j]];
            let subForm = claimForm.controls[i].get(keys[j]) as UntypedFormGroup
            this.mapsubObjects(originalObject, subForm)
            newObject[keys[j]] = originalObject;
          } else {
            /* When form controls are marked as disabled the value will not be set by the value property.
             * To resolve this issue the value needs to be taken from the control itself,
             * insted of mapping the entire form group at once.*/
            if (claimForm.controls[i].get(keys[j]) instanceof UntypedFormGroup) {
              let originalObject: { [key: string]: any } = {}
              let subForm = claimForm.controls[i].get(keys[j]) as UntypedFormGroup
              this.mapsubObjects(originalObject, subForm);
              let subobjectKeys = Object.keys(originalObject);

              /* Checks if the sub object has keys once mapped.
               * if no keys are added ignore the object altogether.
               * Example frachisee key when carrier does not support franchisee in the claim form.*/

              if (subobjectKeys.length > 0) {
                newObject[keys[j]] = originalObject;
              }
            } else {

              newObject[keys[j]] = claimForm.controls[i].get(keys[j])?.value;
            }
          }
        }
        if (keys[j] == 'claimantRole' && claimForm.controls[i].get('claimantRole')?.value) {
          newObject['claimantRole'] = claimForm.controls[i].get('claimantRole')?.value.id;
        }
        if (keys[j] == 'claimant') {
          let claimantPartyType = claimForm.controls[i].get('claimant.claimantPartyType')?.value;
          let claimant = claimForm.controls[i].get('claimant')?.value;
          claimant.claimantPartyType = claimantPartyType?.id;
          newObject['claimant'] = claimant;
        }
        if (keys[j] == 'sender' || keys[j] == 'receiver' || keys[j] == 'purchasingCustomer') {
          /** set a property which is with in the the form group and requires value map */
          let claimantPartyType = claimForm.controls[i].get(`${keys[j]}.claimantPartyType`)?.value;
          if (claimantPartyType) {
            let claimant = claimForm.controls[i].get(keys[j]) as UntypedFormGroup;
            let senderValues = claimant?.getRawValue();
            /**For waranty carriers claimant PartyType has a id*/
            if (claimantPartyType?.id != null || claimantPartyType?.id != undefined) {
              senderValues.claimantPartyType = claimantPartyType?.id;
              newObject[keys[j]] = senderValues;
            }
            else {
              /**For AMX claimant PartyType has no id*/
              senderValues.claimantPartyType = claimantPartyType;
              newObject[keys[j]].claimantPartyType = senderValues.claimantPartyType;
            }
          }
        }
        if ((keys[j] == 'claimType') && (claimForm.controls[i].get('claimType')?.value)) {
          newObject[keys[j]] = claimForm.controls[i].get(keys[j])?.value?.id;
        }
        /*AMX claimType doesn't store the object only the value.*/
        if ((keys[j] == 'claimType') && (claimForm.controls[i].get('claimType')?.value == 0 || claimForm.controls[i].get('claimType')?.value == 1)) {
          newObject[keys[j]] = claimForm.controls[i].get(keys[j])?.value;
        }
        if ((keys[j] == 'goodsDispatchedDateTime' || keys[j] == 'goodsReceivedDateTime'
          || keys[j] == 'initialContactDate'
        ) && claimForm.controls[i].get(keys[j])?.value) {
          newObject[keys[j]] = Utility.convertDateToStringDate(claimForm.controls[i].get(keys[j])?.value)
        }
        if ((keys[j] == 'dateTimeSent'
        ) && claimForm.controls[i].get(keys[j])?.value) {
          newObject[keys[j]] = Utility.convertStringDateToFormattedDateString(claimForm.controls[i].get(keys[j])?.value)
        }
        if ((keys[j] == 'isPreviousClaimant' || keys[j] == 'isGoodsDamagedInTransitWine')
          && claimForm.controls[i].get(keys[j])?.value != null) {
          let value = claimForm.controls[i].get(keys[j])?.value;
          newObject[keys[j]] = value == '1' ? true : false
        }
        if ((keys[j] == 'isInternationalClaimant' || keys[j] == 'isAdditionalLabelsDispatched')
          && claimForm.controls[i].get(keys[j])?.value != null) {
          let value = claimForm.controls[i].get(keys[j])?.value;
          newObject[keys[j]] = value == 'true' ? true : false
        }
        if (keys[j] == 'isGoodsRepairable' && claimForm.controls[i].get(keys[j])?.value != null) {
          let originalvalue = claimForm.controls[i].get(keys[j])?.value;
          if (originalvalue == '0' || originalvalue == '1') {
            newObject[keys[j]] = originalvalue;
          } else {
            newObject[keys[j]] = originalvalue == 'true' ? true : false
          }

        }
        if (keys[j] == 'isSalvageRequired' && claimForm.controls[i].get(keys[j])?.value != null) {
          let originalvalue = claimForm.controls[i].get(keys[j])?.value;
          if (originalvalue == '0' || originalvalue == '1') {
            newObject[keys[j]] = originalvalue;
          } else {
            newObject[keys[j]] = originalvalue == 'true' ? true : false
          }

        }

        if (keys[j] == 'attachments' && claimForm.controls[i].get(keys[j])?.value != null) {
          let formattedAttachmets: any = []
          let originalAttachments: DocumentTypeUploads[]
            = Object.assign([], claimForm.controls[i].get(keys[j])?.value);
          if (!mapOnlySavedFiles) {
            originalAttachments.forEach(atta => {
              let formattedFiles: { name: string, type: string, isDraft: boolean, size: number }[] = [];
              atta.files.forEach(file => {
                if (file instanceof File) {
                  formattedFiles.push({ name: file.name, type: file.type, isDraft: false, size: file.size })
                } else {
                  formattedFiles.push({ name: file.name, type: file.type, isDraft: file.isDraft, size: file.size })
                }
              });
              if (formattedFiles.length > 0) {
                formattedAttachmets.push({ documentType: { name: atta.documentType.name, controlName: atta.documentType.controlName }, files: formattedFiles })
              }
            });
          } else {
            originalAttachments.forEach(atta => {
              let formattedFiles: DraftFile[] = [];
              atta.files.filter(f => !(f instanceof File)).forEach(file => {
                formattedFiles.push({ name: file.name, type: file.type, isDraft: true, size: file.size })
              })
              if (formattedFiles.length > 0) {
                formattedAttachmets.push({ documentType: { name: atta.documentType.name, controlName: atta.documentType.controlName }, files: formattedFiles })
              }
            });
          }
          if (formattedAttachmets.length > 0) {
            newObject[keys[j]] = formattedAttachmets;
          } else {
            newObject[keys[j]] = null;
          }
        }
      }
    }
    let jsonData = JSON.stringify({ ClaimDTO: newObject })
    return jsonData;
  }

  /**
   * Sets the required status of the document types.
   * Sets the visibility of the document types.
   * Sets the display name, information field of the document types.
   * Document Type dropdown in updated with this method.
   * @param carrierConfig - carrier configurtion model is passed in.
   * @param supportingDocumentForm - form group is passed in EX: AMX or Warranty.
   */
  public customizeDocumentTypes(carrierConfig: ConfigurationModel, supportingDocumentForm: UntypedFormGroup) {
    /** Check if supportingDocumentTypes are already added to the form, if added use supporting documents from the form */
    let documents: SupportingDocumentType[] = [];
    let tempDocs: SupportingDocumentType[];

    if (supportingDocumentForm.get('documentTypes')?.value) {
      documents = supportingDocumentForm.get('documentTypes')?.value;
    }
    tempDocs = Object.assign([], documents);
    /* retrieve custom fileds from carrier config
     * according to the control name.*/
    for (let i = 0; i < tempDocs.length; i++) {
      let customField = carrierConfig?.customFields?.filter(cf => cf.fieldName == tempDocs[i].controlName)[0];
      let index = documents.findIndex(d => d.id == tempDocs[i].id);
      if (index > -1) {
        if (customField) {
          /* Set the visibility of the field */
          if (customField.showField == false) {
            documents.splice(index, 1);
            continue;
          } else if (customField.showField == true) {
            documents[index].visible = true;
          }
          /* Set the description of the field */
          if (customField.fieldmessage) {
            documents[index].information = customField.fieldmessage;
          }/* Set the display name of the field */
          if (customField.displayName) {
            documents[index].name = customField.displayName;
          }
        }
        /* remove documents that are not visible by default */
        if (!documents[index].visible) {
          documents.splice(index, 1);
          continue;
        }

        this.updateValueandValidityOfFormControls(supportingDocumentForm);
        /* Set the required status of the documentType dropdown with use of the documentform controls required status */
        let isRequired = supportingDocumentForm.get(documents[index].controlName)?.hasError('required');
        if (isRequired && isRequired == true) {
          documents[index].requiredStatus = RequiredStatus.Required;
        } else {
          documents[index].requiredStatus = RequiredStatus.NotRequired;
        }
      }
    }
    return documents
  }


  public updateUploadedFiles(uploadedFiles: DocumentTypeUploads[], files: File[], selectedDocumentType: SupportingDocumentType): { allFiles: (File | DraftFile)[], uniqueFiles: File[] } | null {
    if (uploadedFiles && files.length > 0) {
      /* gets the selected document Type. */
      let documentTypeToUploadFiles: DocumentTypeUploads;
      documentTypeToUploadFiles = uploadedFiles.filter(doc => doc.documentType.name == selectedDocumentType.name)[0];
      /* Checks if already files added */
      if (documentTypeToUploadFiles) {
        /*Check if selected documentType supports multiple, if not replace any existing file */
        if (selectedDocumentType.multiple == false) {
          documentTypeToUploadFiles.files.splice(0, 1);
          documentTypeToUploadFiles.files.push(files[0]);
        }
        /* Checks if the file name(s) are already added against the seleted document type. */
        let filesNotAdded: File[] = [];
        let doesnotExists = false;

        for (let j = 0; j < files.length; j++) {
          if (documentTypeToUploadFiles.files.length == 0) {
            doesnotExists = true;
          }
          for (let i = 0; i < documentTypeToUploadFiles.files.length; i++) {
            if (documentTypeToUploadFiles.files[i].name == files[j].name) {
              doesnotExists = false;
              break;
            } else {
              doesnotExists = true;
            }
          }
          if (doesnotExists) {
            filesNotAdded.push(files[j]);
          }
        }

        return { allFiles: documentTypeToUploadFiles.files, uniqueFiles: filesNotAdded };
      } else {
        /* First time files are added. update the uploadedFiles array */

        return { allFiles: files, uniqueFiles: files };;
      }
    }
    return null;
  }


  public uploadFiles(uploadedFiles: DocumentTypeUploads[], files: File[], selectedDocumentType: SupportingDocumentType) {
    let documentTypeToUploadFiles: DocumentTypeUploads;
    documentTypeToUploadFiles = uploadedFiles.filter(doc => doc.documentType.name == selectedDocumentType.name)[0];
    if (documentTypeToUploadFiles) {
      for (let t = 0; t < files.length; t++) {
        documentTypeToUploadFiles.files.push(files[t]);
      }
    } else {
      uploadedFiles.push({ documentType: selectedDocumentType, files: files });
    }
  }

  /**
   * retrieves document types from the DB and sets the controls accordingly
   * @param documentFormGroup The form in which the control need to be set.
   */
  public setSupportingDocumentControls(carrierPrefix: string, results: SupportingDocumentType[]) {

    let documentFormGroup: UntypedFormGroup;
    if (carrierPrefix) {
      if (carrierPrefix == Constant.carrierPrefix.amxCarrier.toLowerCase()) {
        documentFormGroup = this.amxSupportingDocumentForm;
      } else {
        documentFormGroup = this.supportingDocumentForm;
      }

      let supportingDocumentTypes: SupportingDocumentType[] = results;
      supportingDocumentTypes.forEach(doc => {
        documentFormGroup.addControl(doc.controlName,
          new UntypedFormControl('', doc.requiredStatus == RequiredStatus.Required ? this.mapValidatorByName({ name: 'required' }) : null));
        /*Set default conditional validators for the docment Types*/
        if (doc.visible == true && doc.requiredForDamageClaims == true)
          this.addDefaultConditionalValidation(documentFormGroup, doc.controlName, 'required', () => this.consignmetInformationForm.get('claimType')?.value?.id == 0)
      });
      documentFormGroup.get('documentTypes')?.setValue(supportingDocumentTypes);
    }
  }
  /**
   * Sets the validation for a control is loaded.
   * @param mainForm form in which the control is located.
   * @param controlName control to set the validation.
   * @param validatorName validator which can be used to set validation.
   * @param predicate the condition which needs to be excuted inorder to the validation to be set.
   */
  public addDefaultConditionalValidation(mainForm: UntypedFormGroup, controlName: string, validatorName: string, predicate: () => boolean) {
    let validator = this.mapValidatorByName({ name: validatorName });
    if (validator) {
      mainForm.get(controlName)?.setValidators(this.customValidatorService.customDependentFieldValidator(predicate, validator))
    }
  }

  public setInitialCopyOfDocumentTypes(carrierPrefix: string) {
    let initalCopy: SupportingDocumentType[] = [];
    let tempArray: SupportingDocumentType[] = [];
    if (carrierPrefix) {
      if (carrierPrefix == Constant.carrierPrefix.amxCarrier.toLowerCase()) {
        tempArray = this.amxSupportingDocumentForm.get('documentTypes')?.value;
        tempArray.forEach(doc => initalCopy.push(Object.assign({}, doc)))
        this.amxSupportingDocumentForm.get('copyDocumentTypes')?.setValue(initalCopy);

      } else {
        tempArray = this.supportingDocumentForm.get('documentTypes')?.value;
        tempArray.forEach(doc => initalCopy.push(Object.assign({}, doc)))
        this.supportingDocumentForm.get('copyDocumentTypes')?.setValue(initalCopy);
      }
    }
  }

  public downloadFile(file: File) {
    let fileUrl = URL.createObjectURL(file);
    if ((file.type.indexOf('application') > -1 && file.type.indexOf('application/pdf') < 0 && file.type.indexOf('application/x-zip-compressed') < 0) ||
      file.type.indexOf('image/tiff') > -1) {

      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      a.href = fileUrl;
      a.download = file.name;
      a.click();
      window.URL.revokeObjectURL(fileUrl);
    } else {
      window.open(fileUrl, '_blank');
    }
  }

  public checkFileSize(files: File[]): { filenames: string, totalFileSize: number } | null {
    if (files.length > 0) {
      let hasOverSized: boolean = false
      let totalFileSize: number = 0;
      let overSizedFiles: string = "<ul>";
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (file.size) {
          const fileSize = (file.size / (1024 * 1024));
          totalFileSize = totalFileSize + fileSize;
          if (fileSize > Constant.maxFileSizeAllowed) {
            overSizedFiles = `${overSizedFiles} <li>${file.name} - ${fileSize.toFixed(2)}MB </li>`;
            hasOverSized = true;
          }
        }
      }
      if (!hasOverSized) {
        return { filenames: "", totalFileSize: totalFileSize };;
      }
      return { filenames: `${overSizedFiles}</ul>`, totalFileSize: totalFileSize };
    }
    return null;
  }

  public checkFileExtensions(files: File[]): { files: File[], filesWithoutExtensions: string } {
    let filesWithoutExtensions: string = "";
    let filesWithExtensions: File[] = [];
    files.forEach(file => {
      if (!file.type) {
        if (!filesWithoutExtensions) {
          filesWithoutExtensions = "<ul>";
        }
        filesWithoutExtensions = `${filesWithoutExtensions} <li>${file.name}</li>`
      } else {
        filesWithExtensions.push(file);
      }
    });
    if (filesWithoutExtensions) {
      filesWithoutExtensions = filesWithoutExtensions + "<ul/>";
    }
    return { files: filesWithExtensions, filesWithoutExtensions: filesWithoutExtensions };
  }

  public getDeviceType(): string {

    const deviceString = navigator.userAgent;

    if (deviceString.indexOf(Device.Android) > -1) {
      return Device.Android;
    } else if (deviceString.indexOf(Device.IPhone) > -1) {
      return Device.IPhone;
    } else if (deviceString.indexOf(Device.IPad) > -1) {
      return Device.IPad;
    } else if (navigator.userAgent.indexOf("Safari") != -1 && navigator.userAgent.indexOf("Chrome") == -1
      && navigator.maxTouchPoints > 1) {
      console.log('Safari - touch device');
      return Device.IPad;
    }
    return Device.Other;
  }

  public renameFileAccordingToDevice(files: File[], deviceType: Device, count: number): File[] {
    let filesRenamed: File[] = [];
    const file = files[0];
    if (files[0].type.indexOf('video') > -1) {
      let fileName = `${deviceType}_video_${count}.${file.name.split('.').pop()}`;
      filesRenamed.push(new File([file], fileName, { type: file.type }));
    }
    else if (files[0].type.indexOf('image') > -1) {
      let fileName = `${deviceType}_image_${count}.jpg`;
      filesRenamed.push(new File([file], fileName, { type: 'image/jpeg' }));
    }
    return filesRenamed;
  }

  public getBrowserType() {
    console.log(navigator.userAgent);
    console.group('The browser-----');
    let foundBrowser: boolean = false;
    let browser: string = "";
    if (navigator.userAgent.indexOf("Safari") != -1 && navigator.userAgent.indexOf("Chrome") == -1) {
      console.log('Safari');
      foundBrowser = true;
      browser = 'Safari'

    } if (navigator.userAgent.indexOf("Firefox") != -1) {
      console.log('Firefox');
      browser = 'Firefox';
      foundBrowser = true;
    } if (navigator.userAgent.indexOf("Chrome") != -1 && (navigator.userAgent.indexOf("Opera") == -1 && navigator.userAgent.indexOf('OPR') == -1
      && navigator.userAgent.indexOf('Edg') == -1)) {
      console.log('Chrome');
      browser = 'Chrome';
      foundBrowser = true;
    } if (navigator.userAgent.indexOf("Edg") != -1) {
      console.log('Edg');
      browser = 'Edg'
      foundBrowser = true;
    }
    if (navigator.userAgent.indexOf("Opera") != -1 || navigator.userAgent.indexOf('OPR') != -1) {
      console.log('Opera');
      browser = 'Opera'
      foundBrowser = true;
    }
    if ((navigator.userAgent.indexOf("MSIE") != -1)) {
      console.log('IE');
      browser = 'IE'
      foundBrowser = true;
    }
    if (!foundBrowser) {
      console.log(navigator.userAgent);
      browser = `UNKNOWN :- ${navigator.userAgent}`;
    }

    return browser;
  }

  public getBrowserVersion() {
    var userAgent = navigator.userAgent, tem,
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|edg|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      console.log('IE ' + (tem[1] || ''));
      return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge|Edg)\/(\d+)/);
      if (tem != null) {
        console.log(tem.slice(1).join(' ').replace('OPR', 'Opera'));
        return tem.slice(1).join(' ').replace('OPR', 'Opera')
      };
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null)
      matchTest.splice(1, 1, tem[1]);
    console.log(matchTest.join(' '));
    return matchTest.join(' ');
  }

  public saveAsDraft(carrierConfig: ConfigurationModel | undefined = undefined) {
    this.commonService.isLoading$.next(true);
    let attachments;
    let draftClaimDto;
    if (carrierConfig?.prefix?.toLowerCase() == Constant.carrierPrefix.amxCarrier.toLowerCase()) {
      this.amxWelcomeForm.get('carrier')?.setValue({ name: carrierConfig.carrierName, code: carrierConfig.prefix }, { onlyself: true });
      this.mapCustomerTypeToClaimForm();
      const labelNuo = (this.amxParcelDetailsForm.get('labelNumber')?.value).replaceAll(/\s/g, '');
      this.amxParcelDetailsForm.get('labelNumber')?.setValue(labelNuo, { onlyself: true });
      if (this.amxClaimantRole && this.amxClaimantRole?.value?.id > -1) {
        switch (this.amxClaimantRole.value.id) {
          case Constant.claimantRole[0].id:
            {
              this.amxCustomerInfor.receiver.get('abnNumber')?.setValue('', { onlyself: true });
              this.amxCustomerInfor.purchasingCustomer.get('abnNumber')?.setValue('', { onlyself: true });
              break;
            }
          case Constant.claimantRole[1].id: {
            this.amxCustomerInfor.sender.get('abnNumber')?.setValue('', { onlyself: true });
            this.amxCustomerInfor.purchasingCustomer.get('abnNumber')?.setValue('', { onlyself: true });
            break;
          }
          case Constant.claimantRole[2].id: {
            this.amxCustomerInfor.sender.get('abnNumber')?.setValue('', { onlyself: true });
            this.amxCustomerInfor.receiver.get('abnNumber')?.setValue('', { onlyself: true });
            break;
          }
        }
      }
      if (!this.amxTermsAndConditionsForm.get('ipAddress')?.value || this.amxTermsAndConditionsForm.get('ipAddress')?.value == "") {
        this.amxTermsAndConditionsForm.get('ipAddress')?.setValue('0.0.0.0', { onlyself: true });
      }
      draftClaimDto = this.mapClaimFormToJSON(this.amxClaimForm);
      if (environment.showConsoleMessages) {
        console.log(draftClaimDto);
      }
      attachments = this.amxSupportingDocumentForm.get('attachments')?.value
    }
    else {
      this.commonService.isLoading$.next(true);
      const labelNuo = (this.consignmetInformationForm.get('consignmentNoteNo')?.value).replaceAll(/\s/g, '');
      this.consignmetInformationForm.get('consignmentNoteNo')?.setValue(labelNuo, { onlyself: true });
      this.updateStreetAddress()
      this.mapClaimantInforBasedOnRole();
      this.updateClaimantType();
      draftClaimDto = this.mapClaimFormToJSON(this.claimForm);
      if (environment.showConsoleMessages) {
        console.log(draftClaimDto);
      }

      attachments = this.supportingDocumentForm.get('attachments')?.value
    }

    this.appService.submitDraftClaim(draftClaimDto, attachments).subscribe(
      (response) => {
        this.commonService.isLoading$.next(false);
        this._modalCallBackMethod ?
          this._modalCallBackMethod('Successful!', `The draft claim was saved successfully.`).result.then(value => {
            this.clearSessionStorage();
            this.commonService.isUploaded$.next(false);
            if (carrierConfig?.prefix?.toLowerCase() == Constant.carrierPrefix.amxCarrier.toLowerCase()) {
              window.location.href = 'https://www.aramex.com.au';
            }
            else {
              window.location.reload();
              this.resetClaimForm(carrierConfig);

            }
          })
          : console.log(response);
      },
      (errorResponse) => {
        this.commonService.isLoading$.next(false);
        console.log(errorResponse)

        if (errorResponse) {
          if (environment.showConsoleMessages) {
            console.log(errorResponse.error);
            console.log(errorResponse.message);
          }
          const title = 'Error';

          let textContent = "";
          if (errorResponse) {
            if (errorResponse.error) {
              if (errorResponse.error.Message) {
                textContent = errorResponse.error.Message + "<br>";
              }
              else {
                textContent = errorResponse.error + "<br>";
              }
            }
            if (errorResponse.message) {
              textContent = textContent + errorResponse.message;
            }
          }
          else {
            textContent = "No error response"
          }

          if (!textContent) {
            textContent = errorResponse;
          }
          if (this._modalCallBackMethod) {
            this._modalCallBackMethod(title, textContent);
          }
        }
      }
    );
  }

  public mapClaimFormDataToClaimForm(carrierPrefix: string, claimFormJSON: any, carrierConfig: ConfigurationModel) {

    if (claimFormJSON) {
      let claimFormJSONObject = JSON.parse(claimFormJSON);
      //let attachmentsJSONObject = JSON.parse(claimFormJSONObject['ClaimDTO']['attachments']);
      //claimFormJSONObject['ClaimDTO']['attachments'] = attachmentsJSONObject;
      let claimFormObject = this.mapClaimFormData(claimFormJSONObject, 'ClaimDTO');
      if (carrierPrefix == Constant.carrierPrefix.amxCarrier) {
        this.setAmxClaimData(claimFormObject, true);
        this.mapAttachmentToClaimForm(carrierPrefix, claimFormObject, this.amxSupportingDocumentForm);
        this.setRequiredDocumentsValidators();
        return;
      }

      if (claimFormObject) {
        let carrier = this.mapClaimFormData(claimFormObject, 'carrier');
        if (carrier) {
          let carrierCode = carrier['code']
          if (carrierCode != carrierPrefix) {
            return;
          }
        } else {
          return;
        }
        for (let i = 0; i < this.claimForm.controls.length; i++) {
          let formGoup = this.claimForm.get([i]) as UntypedFormGroup;
          let keys = Object.keys(formGoup.controls);
          for (let j = 0; j < keys.length; j++) {
            if (claimFormObject[keys[j]] == null) {
              continue;
            }
            formGoup.get(keys[j])?.patchValue(claimFormObject[keys[j]]);

            if (keys[j] == 'claimant') {
              let claimantObject = this.mapClaimFormData(claimFormObject, 'claimant');
              let customerType = Constant.claimantTypes.filter(ct => claimantObject && ct.id == parseInt(claimantObject['claimantPartyType']))[0];
              formGoup.get(`${keys[j]}.claimantPartyType`)?.setValue(customerType != undefined ? customerType : '', { onlyself: true });
            }
            if (keys[j] == 'claimantRole') {
              let customerType = Constant.claimantRole.filter(cr => claimFormObject && cr.id == parseInt(claimFormObject['claimantRole']))[0];
              formGoup.get(`${keys[j]}`)?.setValue(customerType != undefined ? customerType : '', { onlyself: true });
            }
            if (keys[j] == 'claimType') {
              let claimType;
              let defaultValues: any[] | null = this.getCustomValuesFromCarrerConfig(carrierConfig, 'claimType') as any[];
              if (defaultValues) {
                claimType = defaultValues.filter(ct => claimFormObject && ct.id == parseInt(claimFormObject['claimType']))[0];
              } else {
                claimType = Constant.claimType.filter(ct => claimFormObject && ct.id == parseInt(claimFormObject['claimType']))[0];
              }

              formGoup.get(`${keys[j]}`)?.setValue(claimType != undefined ? claimType : '', { onlyself: true });
              this.setGoodsReceivedDate(formGoup.get(`${keys[j]}`)?.value, carrierConfig);
              this.updateDamageLocationBasedOnClaimType(carrierConfig);
            }
            if ((keys[j] == 'goodsDispatchedDateTime' || keys[j] == 'goodsReceivedDateTime' || keys[j] == 'initialContactDate'
            ) && claimFormObject[keys[j]]) {
              formGoup.get(`${keys[j]}`)?.setValue(Utility.convertDateStringToNgbDate(claimFormObject[keys[j]]), { onlyself: true });
            }
            if (keys[j] == 'isPreviousClaimant') {
              /* map the radio buttons, from the form object. */
              let value = claimFormObject[keys[j]] ? '1' : '0';
              formGoup.get(`${keys[j]}`)?.setValue(value, { onlyself: true });
            }
            if (keys[j] == 'isInternationalClaimant') {
              /* map the radio buttons, from the form object. */
              let value = claimFormObject[keys[j]] ? 'true' : 'false';
              formGoup.get(`${keys[j]}`)?.setValue(value, { onlyself: true });
            }
          }
        }
        this.mapAttachmentToClaimForm(carrierPrefix, claimFormObject, this.supportingDocumentForm);
      }
    }
  }

  public mapAttachmentToClaimForm(carrierPrefix: string, claimFormObject: any, form: UntypedFormGroup) {

    if (claimFormObject != null && claimFormObject['attachments']) {
      const documentTypes: SupportingDocumentType[] = claimFormObject['documentTypes'];
      var attachmentsData: any;
      if (typeof claimFormObject['attachments'] === "string") {
        attachmentsData = JSON.parse(claimFormObject['attachments']);
      } else {
        attachmentsData = claimFormObject['attachments'];
      }
      for (var i = 0; i < attachmentsData.length; i++) {
        const attchment: DocumentTypeUploads = attachmentsData[i]
        const files: DraftFile[] = [];
        for (var x = 0; x < attchment['files'].length; x++) {
          const file = attchment['files'][x];
          const newFile: DraftFile = { name: file.name, type: file.type, size: file.size, isDraft: true };/*[''], file.name, { type: file.type }*/
          files.push(newFile);
        }
        attchment['files'] = files;
        attchment['documentType'] = documentTypes.filter(doc => doc.controlName == attachmentsData[i].documentType.controlName)[0];
      }
      form.get('attachments')?.setValue(attachmentsData);

      form.get('documentTypes')?.setValue(documentTypes);
      if (claimFormObject['documentType']) {
        const selectedDocumentType = documentTypes.filter(d => d.id == claimFormObject['documentType'].id)[0];
        form.get('documentType')?.setValue(selectedDocumentType);
      }
      form.get('copyDocumentTypes')?.setValue(claimFormObject['copyDocumentTypes']);
      if (claimFormObject['uploadedTotalFileSize']) {
        form.get('uploadedTotalFileSize')?.setValue(claimFormObject['uploadedTotalFileSize']);
      }

    }
  }

  public openDraftFile(file: DraftFile, consignmentNoteNumber: string, carrierPrefix: string) {

    let fileUrl;
    this.appService.getDraftAttachment(consignmentNoteNumber, file.name, carrierPrefix).subscribe(attachmentURL => {
      if (attachmentURL) {

        fileUrl = attachmentURL;

        if ((file.type.indexOf('application') > -1 && file.type.indexOf('application/pdf') < 0 && file.type.indexOf('application/x-zip-compressed') < 0) ||
          file.type.indexOf('image/tiff') > -1) {

          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style.display = "none";
          a.href = fileUrl;
          a.download = file.name;
          a.click();
          window.URL.revokeObjectURL(fileUrl);
        } else {
          window.open(fileUrl, '_blank');
        }
      }
    });
  }


  public getCustomValuesFromCarrerConfig(carrierConfig: ConfigurationModel, fieldName: string) {

    let defaultValues = carrierConfig.customFields?.filter(cf => cf.fieldName === fieldName).map(field => field.defaultValues)[0];
    if (defaultValues) {
      return defaultValues;
    }
    return null;

  }

  public setBankDetailVisibilityAndValidation(carrierConfig: ConfigurationModel, isEligible: boolean)
  {
    const bankComponent = carrierConfig.navigation?.filter(com => com.componentName === Constant.claimFormComponentNames.Bank)[0];
    if (!bankComponent?.isCarrierSepcific) {
      this.navigationService.setComponentVisibility(Constant.claimFormComponentNames.Bank, carrierConfig, isEligible);
      if (isEligible) {
        this.addCarrierSpecificValidationByComponent(carrierConfig, Constant.claimFormComponentNames.Bank);

      } else {
        this.removeValidationByComponentName(Constant.claimFormComponentNames.Bank);
      }
    }
    

  }
}

